import axios from 'axios';
import { API_URL } from '../../utils/static';

//QUESTIONNAIRES
export const generateQuestionnaires = () => {
  return axios.post(`${API_URL}/api/kyc/v1/investor/questionSectionTypes`);
};
export const getQuestionnaires = () => {
  return axios.get(`${API_URL}/api/kyc/v1/investor/questionSectionTypes`);
};
export const checkQuestionnaireStatus = ({ id }) => {
  return axios.post(`${API_URL}/api/kyc/v1/investor/checkQuestionnaire`, {
    questionSectionTypeId: id
  });
};
export const getRejectedEntities = () => {
  return axios.get(`${API_URL}/api/kyc/v1/investor/questionnaire`, {
    params: { status: 'UNACCEPTED' }
  });
};
const saveQuestion = (
  {
    questionSectionTypeId,
    questionId,
    file,
    availableAnswerId,
    answer,
    clearAll,
    group
  }) => {
  const formData = new FormData();
  formData.append('questionId', questionId);
  formData.append('questionSectionTypeId', questionSectionTypeId);
  if (clearAll) formData.append('clearAll', clearAll);
  if (file) formData.append('file', file);
  if (availableAnswerId) formData.append('availableAnswerId', availableAnswerId);
  if (answer) formData.append('answer', answer);
  if (group || group >= 0) formData.append('group', group);

  return axios.post(`${API_URL}/api/kyc/v1/investor/answer`, formData);
};
const getAllowedQuestsToAdd = () => {
  return axios.get(`${API_URL}/api/kyc/v1/investor/questionnaires`);
};
const requestQuest = (questId) => {
  return axios.post(`${API_URL}/api/kyc/v1/investor/questionnaires`, {
    questionSectionTypeId: questId
  });
};

//SUMSUB
export const createVerificationInstance = () => (
  axios.post(`${API_URL}/api/kyc/v1/investor/questionSectionTypes`)
);
export const getSumSubQuestionnaire = () => {
  return axios.get(`${API_URL}/api/kyc/v1/investor/verification/questionnaire`);
};
export const postSumSubAnswer = ({ questionId, file, availableAnswerId, answer }) => {
  const formData = new FormData();
  formData.append('questionId', questionId);
  if (file) formData.append('file', file);
  if (availableAnswerId) formData.append('availableAnswerId', availableAnswerId);
  if (answer) formData.append('answer', answer);

  return axios.post(`${API_URL}/api/kyc/v1/investor/verification/questionnaire/answer`, formData);
};
export const getSumSubStatus = () => {
  return axios.get(`${API_URL}/api/kyc/v1/investor/verification/status`);
};
const startSumSubCheck = () => {
  return axios.get(`${API_URL}/api/kyc/v1/sumsub/verification/check`);
};

//VERIF
const getVerifDocTypes = () => (
  axios.get(`${API_URL}/api/kyc/v1/investor/veriff/documentTypes`)
);
/**
 * @returns {Promise<AxiosResponse<T>>}
 * @param data: {
 *    veriffDocumentTypeId
 *    firstName
 *    lastName
 *    dob
 *    countryId
 *    documentNumber
 * }
 */
const createVerifSection = (data) => (
  axios.post(`${API_URL}/api/kyc/v1/investor/veriff/section`, data)
);
const postVerifSectionDoc = ({ file, sectionId }) => {
  //formData
  return axios.post(`${API_URL}/api/kyc/v1/investor/veriff/document`);
};
const getVerifSections = () => (
  axios.get(`${API_URL}/api/kyc/v1/investor/veriff/sections`)
);
const getVerifStatus = (sectionId) => (
  axios.get(`${API_URL}/api/kyc/v1/investor/veriff/status`, { params: { sectionId: '1' } })
);

//DOCS
//requested docs
const getRequestedDocuments = () => {
  return axios.get(`${API_URL}/api/kyc/v1/investor/questionnaire/document/requestDocuments`);
};
//docs from questionnaires
const getQuestsDocuments = () => {
  return axios.get(`${API_URL}/api/kyc/v1/investor/questionnaire/document/documentAnswers`);
};
//allowed doc sections to add
const getDocumentsSections = () => {
  return axios.get(`${API_URL}/api/kyc/v1/investor/questionnaire/document/sections`);
};
//allowed doc types to add by section
const getDocumentSectionTypes = ({ sectionId }) => {
  return axios.get(`${API_URL}/api/kyc/v1/investor/questionnaire/document/types`, {
    params: { kycDocumentSectionId: sectionId }
  });
};
//get doc request by id
const getDocumentRequest = (requestId) => {
  return axios.get(`${API_URL}/api/kyc/v1/investor/questionnaire/document/requestDocument`, {
    params: { requestDocumentId: requestId }
  });
};
//create upload doc request
const requestDocument = ({ docTypeId }) => {
  return axios.post(`${API_URL}/api/kyc/v1/investor/questionnaire/document/requestDocument`, {
    kycTypeDocumentId: docTypeId
  });
};
//upload doc
const uploadDocument = ({ requestDocumentId, file }) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('requestDocumentId', requestDocumentId);

  return axios.post(`${API_URL}/api/kyc/v1/investor/questionnaire/document`, formData);
};
//ACTIVITY
const getInvestorActivity = (params) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/questionnaire/logs`, { params: { ...params } });
};

//LEGACY
export const uploadDoc = ({ file, type, subtype }) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);
  formData.append('subtype', subtype);

  return axios.post(`${API_URL}/api/kyc/v1/investor/upgradeTier2/document`, formData);
};

const applyTier2 = () => {
  return axios.post(`${API_URL}/api/kyc/v1/investor/upgradeTier2`);
};

const getSections = () => {
  return axios.get(`${API_URL}/api/kyc/v1/investor/questionSections`);
};

const getQuestionnaireSections = (id) => {
  return axios.get(`${API_URL}/api/kyc/v1/investor/questionSectionTypes/questions`, {
    params: { questionSectionTypeId: id }
  });
};

const getQuestions = (id) => {
  return axios.get(`${API_URL}/api/kyc/v1/investor/questions`, {
    params: { questionSectionId: id }
  });
};

const getAnswers = (params) => {
  return axios.get(`${API_URL}/api/kyc/v1/investor/availableAnswers`, { params: params });
};

export const kycApi = {
  generateQuestionnaires,
  getQuestionnaires,
  getSumSubQuestionnaire,
  getAllowedQuestsToAdd,
  requestQuest,
  createVerificationInstance,
  postSumSubAnswer,
  getSumSubStatus,
  startSumSubCheck,
  getRequestedDocuments,
  getQuestsDocuments,
  getDocumentsSections,
  getDocumentSectionTypes,
  getDocumentRequest,
  requestDocument,
  uploadDocument,
  uploadDoc,
  getInvestorActivity,
  applyTier2,
  getSections,
  getQuestionnaireSections,
  checkQuestionnaireStatus,
  getRejectedEntities,
  getQuestions,
  getAnswers,
  saveQuestion,
  getVerifDocTypes,
  createVerifSection,
  getVerifSections,
  postVerifSectionDoc,
  getVerifStatus
};

export const kycApiKeys = {
  getVerifDocTypes: 'investor.getVerifDocTypes',
  createVerifSection: 'investor.createVerifSection',
  getVerifSections: 'investor.getVerifSections',
  postVerifSectionDoc: 'investor.postVerifSectionDoc',
  getVerifStatus: 'investor.getVerifStatus'
};
