import React, { useRef, useState } from 'react';
import { Modal, SyncIndicator } from '@aragon/ui';
import { useController } from 'react-hook-form';
import { DocumentLine } from '../../atoms/DocumentLine';
import { Button } from '../../../../ui/atoms';
import { Col, Container, Row } from '../../../../ui/layout';
import styled from 'styled-components';
import {Body3Light, Label2, Span} from '../../../../ui/typography';
import { COLORS } from '../../../../utils/static/colors';

let camera_stream = null;
let media_recorder = null;
let blobs_recorded = [];
const video_type = MediaRecorder.isTypeSupported('video/webm')
  ? { type: 'video/webm', extension: 'webm' }
  : { type: 'video/mp4', extension: 'mp4' };

export const RecordVideo = ({ label, required, doc, setDoc, error }) => {
  const [active, setActive] = useState(false);
  const [video, setVideo] = useState(null);
  const [recording, setRecording] = useState(false);
  const [videoError, setVideoError] = useState('');
  const ref = useRef();

  const getCameraStream = async () => {
    try {
      camera_stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
      ref.current.srcObject = camera_stream;
      if (videoError) setVideoError('');
      return camera_stream;
    } catch (e) {
      console.error(e);
      const notAllowedError = e?.name === 'NotAllowedError';
      setVideoError(
        notAllowedError
        ? 'Access to camera is denied.\nPlease provide access to the camera in your browser settings.'
        : 'Can\'t get access to the camera'
      );
    }
  };

  const openModal = async () => {
    setActive(true);
    if (video) setVideo(null);
    await getCameraStream();
  };

  const startRecord = async () => {
    try {
      const stream = camera_stream ? camera_stream : await getCameraStream();

      media_recorder = new MediaRecorder(stream, { mimeType: video_type.type });
      media_recorder.ondataavailable = (e) => {
        blobs_recorded.push(e.data);
      };

      media_recorder.start(200);
      setRecording(true);
    } catch (e) {
      console.error(e);
    }
  };

  const stopStream = () => {
    try {
      if (media_recorder) {
        media_recorder.stop();
        media_recorder.ondataavailable = null;
      }

      const tracks = ref.current.srcObject.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      ref.current.srcObject = null;
    } catch (e) {
      console.error(e);
    }
  };

  const stopRecord = () => {
    stopStream();
    setRecording(false);

    const file = new File(blobs_recorded, `recorded_video.${video_type.extension}`, {
      type: video_type.type
    });
    setVideo(file);

    camera_stream = null;
    media_recorder = null;
    blobs_recorded = [];
  };

  const saveVideo = () => {
    if (video) {
      setDoc(video);
      setActive(false);
    }
  };

  const closeModal = () => {
    stopStream();
    setActive(false);
  };

  return (
    <div>
      {label && <Label2>
        {label}
        {required && <Span color={COLORS.aragonBlue}>&nbsp;*</Span>}
      </Label2>}

      {doc && (
        <DocumentLine doc={{ name: doc.name }} onDelete={() => setDoc(null)} hideLastBorder />
      )}

      <Container marginTop={20}>
        <Button
          label={'Record video'}
          onClick={openModal}
          fontWeight={300}
          paddingRight={'20'}
          paddingLeft={'20'}
          size={'small'}
        />
      </Container>

      {error && (
        <Body3Light color={COLORS.red} marginTop={12}>
          {error.message}
        </Body3Light>
      )}

      <Modal visible={active} onClose={closeModal} padding={40}>
        <Video ref={ref} autoPlay hidden={video && !recording} />
        {video && !recording && <Video src={URL.createObjectURL(video)} autoPlay loop controls />}
        {videoError && <Body3Light color={COLORS.red} preWrap>{videoError}</Body3Light>}

        <Row justifyContent={'center'} margin={-20} marginTop={20}>
          <Col auto>
            <Button
              label={'Save'}
              onClick={saveVideo}
              mode={'strong'}
              disabled={!video || recording}
            />
          </Col>
          <Col auto>
            <Button label={'Start'} onClick={startRecord} disabled={videoError || recording} />
          </Col>
          <Col auto>
            <Button label={'Stop'} onClick={stopRecord} disabled={!recording} />
          </Col>
        </Row>
      </Modal>

      <SyncIndicator visible={recording}>Recording...</SyncIndicator>
    </div>
  );
};

export const RecordVideoForm = ({ label, required, form }) => {
  const {
    field: { ref, ...field },
    fieldState: { error }
  } = useController({
    ...form,
    defaultValue: null
  });

  return (
    <RecordVideo
      label={label}
      required={required}
      doc={field.value}
      setDoc={(video) => field.onChange(video)}
      error={error}
    />
  );
};

const Video = styled.video`
  width: 100%;
  height: 320px;
  ${(p) => (p.hidden ? 'display: none;' : '')}
`;
