import React, { useState } from 'react';
import { Col, Row, Typography, Skeleton, Space } from 'antd';
import { Box } from '../../../customAragon/Box';
import { AntdTypographyWrapper } from '../../../../ui/typography';
import { UserAddOutlined } from '@ant-design/icons';
import { COLORS } from '../../../../utils/static/colors';
import styled from 'styled-components';
import { AddReferralModal, AddRecommenderModal } from '../../../investor/modals';
import { ProfileViewer, ProfileViewerSkeleton } from '../../molecules/ProfileViewer';
import { Container } from '../../../../ui/layout';


export const ProfileReferralsView = ({ referrals, recommeders }) => {
  const [addRefferalOpened, setAddRefferalOpened] = useState(false);
  const [addRecommenderOpened, setAddRecommenderOpened] = useState(false);

  return (
    <AntdTypographyWrapper>
      <Box>
        <Row wrap={false} gutter={24}>
          <Col span={12}>
            <Typography.Title level={4}>
              My referrals <AddButton onClick={() => setAddRefferalOpened(true)} />
            </Typography.Title>
            <AccountsList
              accounts={referrals}
              emptyTitle={'No referrals yet'}
            />
          </Col>

          <Col span={12}>
            <Typography.Title level={4}>
              My recommenders <AddButton onClick={() => setAddRecommenderOpened(true)} />
            </Typography.Title>
            <AccountsList
              accounts={recommeders}
              emptyTitle={'No recommenders yet'}
            />
          </Col>
        </Row>
      </Box>


      <AddReferralModal
        open={addRefferalOpened}
        onClose={() => setAddRefferalOpened(false)}
      />
      <AddRecommenderModal
        open={addRecommenderOpened}
        onClose={() => setAddRecommenderOpened(false)}
      />
    </AntdTypographyWrapper>
  );
};

const AccountsList = ({ accounts, emptyTitle = 'Nothing to display' }) => {
  const { error, list, isLoading } = accounts;

  if (isLoading) return (<Container marginTop={16}>
    {[1, 2, 3].map((item => (
      <Container marginBottom={12} key={item}>
        <ProfileViewerSkeleton/>
      </Container>
    )))}
  </Container>);

  if (error) return <Typography.Text type={'danger'}>An error occurred</Typography.Text>;

  if (!list || list.length < 1) {
    return <Typography.Text>{emptyTitle}</Typography.Text>;
  }

  return (
    <Container marginTop={16}>
      {list.map(item => (
        <ProfileViewer
          avatarUrl={item.avatar}
          name={`${item.firstName} ${item.lastName}`}
          size={'medium'}
          marginBottom={12}
          key={item.id}
        />
      ))}
    </Container>
  );
};

const AddButton = styled(UserAddOutlined)`
  width: 32px;
  height: 32px;
  color: ${COLORS.aragonBlue};
  cursor: pointer;
  margin-left: 8px;
  border-radius: 6px;
  transition: 0.2s;
  :hover {
    box-shadow: 0px 2px 10px 2px rgba(8, 190, 229, 0.15) inset;
  }
  :active {
    box-shadow: 0px 2px 10px 2px rgba(8, 190, 229, 0.32) inset;
  }
  
  > svg {
    width: 100%;
  }
`;
