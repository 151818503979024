import React, { useEffect, useMemo, useState } from 'react';
import { SyncIndicator } from '@aragon/ui';
import { useParams, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { adminKYCApi } from '../../../../api/admin/kyc';
import { QuestionnaireView } from '../../../../modules/Questionnaire';
import { history, scrollToTop } from '../../../../routing';
import {ENABLED_QUESTS_TO_INTERACT_STATUSES_ADMIN, SHOWED_SECTIONS_COUNT} from '../../../../utils/static/kyc';
import { Toast } from '../../../../modules/kycModule/components/Toast';
import { RejectModal } from '../../../../modules/Questionnaire/components/modals/RejectModal';
import { userActions } from '../../../../store/user';
import { useDispatch } from 'react-redux';
import {kycApi} from "../../../../api/kyc";
import {kycHelpers} from "../../../../utils/helpers/kyc";
import {entitiesActions} from "../../../../store/entities";

export const QuestionnaireAnswers = () => {
  const dispatch = useDispatch();
  const { id, questionnaireId } = useParams();
  const { state } = useLocation();
  const [toast, setToast] = useState('');
  const [currentSection, setCurrentSection] = useState(0);
  const [rejectedQuestions, setRejectedQuestions] = useState(null);
  const [isModalActive, setModalActive] = useState(false);
  const [comment, setComment] = useState('');
  const [commentError, setCommentError] = useState('');

  const { data: res, refetch: refetchQuest, isFetching } = useQuery(
    ['getQuestionnaireAnswers'],
    () => adminKYCApi.getQuestionnaireAnswers({ investorId: id, questionnaireId: questionnaireId }),
    {
      cacheTime: 'Infinity',
      onSuccess: async ({data: questionnaire}) => {
        try {
          if (questionnaire?.status === 'QUESTION_UNACCEPTED' || questionnaire?.status === 'UNACCEPTED') {
            const res = await adminKYCApi.getRejectedEntities(id);
            const rejectedQuestions = res?.data?.question;

            const { rejectedObj } = kycHelpers.getRejectedObject(
              questionnaire,
              rejectedQuestions
            );

            if (rejectedObj) setRejectedQuestions(rejectedObj);
          }
        } catch (e) {
        }
      }
    }
  );
  const { mutateAsync: setQuestionnaireStatus } = useMutation(({ status, comment }) =>
    adminKYCApi.setQuestionnaireStatus({
      investorId: id,
      status: status,
      questionnaireId: questionnaireId,
      comment: comment
    })
  );

  useEffect(() => {
    dispatch(userActions.hideAppContainer());
    return () => dispatch(userActions.showAppContainer());
  }, []);

  const stepsCount = useMemo(() => {
    if (res?.data?.questionSections)
      return Math.ceil(res.data.questionSections.length / SHOWED_SECTIONS_COUNT);
    return 0;
  }, [res]);

  const statusSettings = useMemo(() => {
    if (res?.data) {
      const status = res?.data?.status;
      const isRejected = status === 'UNACCEPTED';
      const isApproved = status === 'ACCEPTED';
      return {
        isRejected: isRejected,
        isApproved: isApproved,
        allowReject: !isRejected && ENABLED_QUESTS_TO_INTERACT_STATUSES_ADMIN[status],
        allowApprove: !isApproved && ENABLED_QUESTS_TO_INTERACT_STATUSES_ADMIN[status],
      }
    }

    return null;
  }, [res]);

  const rejectQuestionnaire = async () => {
    try {
      if (comment) {
        await setQuestionnaireStatus({ status: 'UNACCEPTED', comment });
        setToast('Rejected');
        await refetchQuest();
      } else {
        setCommentError('Required');
      }
    } catch (e) {
      let errorMessage = e?.response?.data?.error?.message;
      if (!errorMessage) errorMessage = 'Something went wrong';

      dispatch(entitiesActions.addToast(errorMessage));
      console.log(e);
    }
  };
  const acceptQuestionnaire = async () => {
    try {
      await setQuestionnaireStatus({ status: 'ACCEPTED' });
      setToast('Accepted');
      await refetchQuest();
    } catch (e) {
      let errorMessage = e?.response?.data?.error?.message;
      if (!errorMessage) errorMessage = 'Something went wrong';

      dispatch(entitiesActions.addToast(errorMessage));
      console.log(e);
    }
  };

  const onNext = () => {
    if (currentSection + 1 < stepsCount) {
      setCurrentSection(currentSection + 1);
      scrollToTop();
    }
  };
  const onBack = () => {
    if (currentSection > 0) {
      setCurrentSection(currentSection - 1);
      scrollToTop();
    } else {
      state && state.prevPath ? history.push(state.prevPath) : history.goBack();
    }
  };

  const addRejectedQuestion = (questionId) => {
    setRejectedQuestions({ ...rejectedQuestions, [questionId]: 'fake_comment' });
  };

  // const loading =
  //   isFetching || !res || res?.data?.investorQuestionSectionTypeId !== questionnaireId;

  const loading = isFetching;
  return (
    <div>
      {!loading && res?.data && (
        <QuestionnaireView
          questionnaire={res.data}
          currentSection={currentSection}
          onNext={onNext}
          onBack={onBack}
          adminView
          rejectQuestionnaire={() => setModalActive(true)}
          acceptQuestionnaire={acceptQuestionnaire}
          rejectedQuestions={rejectedQuestions}
          addRejectedQuestion={addRejectedQuestion}
          statusSettings={statusSettings}
          stepsCount={stepsCount}
          setCurrentSection={setCurrentSection}
        />
      )}
      {!loading && !res?.data && <div>
        Something went wrong
      </div>}

      <RejectModal
        active={isModalActive}
        onClose={() => setModalActive(false)}
        comment={comment}
        setComment={setComment}
        error={commentError}
        onSubmit={rejectQuestionnaire}
      />

      <SyncIndicator visible={loading}>Loading...</SyncIndicator>
      <Toast toast={toast} removeToast={() => setToast('')} />
    </div>
  );
};
