import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GU } from '@aragon/ui';
import { AttachedFile, RadioGroupForm, RecordVideoForm } from '../../../../components';
import {
  EmailInput,
  PhoneInput,
  CheckboxGroupForm,
  AddressBox,
  CountryInput
} from '../../../kycModule/Fields';
import { FormDatePicker, FormInput } from '../../../kycModule/components';
import { Container } from '../../../../ui/layout';
import { RejectButton, Checkbox } from '../../../../ui/atoms';
import { Body1Light, Body2Light, Label2, Span } from '../../../../ui/typography';
import { FileUploadForm } from '../../../kycModule/Fields/FileUpload';
import { useMutation } from 'react-query';
import { adminKYCApi } from '../../../../api/admin/kyc';
import { COLORS } from '../../../../utils/static/colors';
import { RejectModal } from '../modals/RejectModal';
import { SignatureForm } from '../../../kycModule/Fields/SignatureForm';
import { useWatch } from 'react-hook-form';
import { ENABLED_QUESTS_TO_INTERACT_STATUSES_ADMIN } from '../../../../utils/static/kyc';

let isRequiredSave = '';
export const Question = (
  {
    name,
    question,
    control,
    watch,
    trigger,
    submitCount,
    adminView,
    rejectedQuestions,
    addRejectedQuestion,
    insertedQuestions,
    toggleAutoInsertQuestion,
    questionnaireId,
    questionnaireStatus,
    preFilledQuestions,
    disableAll,
    disableQuestion
  }) => {
  const [visible, setVisible] = useState(
    question && question?.visibleOperator ? question?.visibleOperator === 'AND' : true
  );
  const fields = useWatch({
    control,
    name: question.notRequiredConditions ? question.notRequiredConditions.map((item) => item) : null
  });

  //isVisible logic
  if (question && question.visibleConditions) {
    const conditions = question.visibleConditions;
    const operator = question.visibleOperator;
    let fields = watch(conditions.map((item) => item.id));

    let isVisible = operator === 'AND';
    for (const [index, value] of fields.entries()) {
      if (
        operator === 'AND' &&
        (Array.isArray(value)
          ? !value.includes(conditions[index].value)
          : conditions[index].value !== value)
      ) {
        isVisible = false;
        break;
      }
      if (
        operator === 'OR' &&
        (Array.isArray(value)
          ? value.includes(conditions[index].value)
          : conditions[index].value === value)
      ) {
        isVisible = true;
        break;
      }
    }

    if (visible !== isVisible) setVisible(isVisible);
  }

  //isRequiredLogic
  const isRequired = useMemo(() => {
    if (adminView || !question || !question.isRequired) return null;

    if (!question.notRequiredConditions) return 'Required';

    if (question.notRequiredConditions) {
      const triggerFields = () => {
        setTimeout(() => {
          trigger([question.id, ...question.notRequiredConditions]);
        }, 500);
      };

      let returnValue = 'One of the fields is required';

      for (const [index, value] of fields.entries()) {
        if (value) returnValue = null;
      }

      if (submitCount > 0 && isRequiredSave !== '' && isRequiredSave !== returnValue) triggerFields();

      return returnValue;
    }
  }, [fields, submitCount]);

  useEffect(() => {
    isRequiredSave = isRequired;
  }, [isRequired]);


  const RenderedComponent = useMemo(
    () => getRenderedComponent(question.type, adminView),
    [question.type, adminView]
  );

  const validateByType = useMemo(
    () => ({
      CONFIRMATIONS: (v) => v.length === question.availableAnswers.length || 'Confirm all items'
    }),
    []
  );


  const myValue = control['_formValues'][question.id]; //ToDo: toDelete?

  const isRejected = useMemo(() => {
    return rejectedQuestions ? rejectedQuestions[question.id] : null;
  }, [rejectedQuestions, question]);

  const isDisabled = useMemo(() => {
    return (disableAll && !isRejected) || disableQuestion || preFilledQuestions[question?.id];
  }, [disableAll, isRejected, disableQuestion, preFilledQuestions, question]);

  if (RenderedComponent && visible) {
    const validations = validateByType[question.type];

    return (
      <Container>
        {/*{question.question && <Body2Light>{question.question}</Body2Light>}*/}

        <RenderedComponent
          form={{
            name: name || question.id,
            control,
            rules: {
              required: isRequired,
              validate: validations
            }
          }}
          label={question.question}
          question={question}
          adminView={adminView}
          disabled={isDisabled}
          hideField={insertedQuestions[question.id]}
          required={isRequired}
          clearRegex={question.clearRegex}
        >
          {question.autoInsert && (
            <Checkbox
              label={question.autoInsert?.text}
              checked={insertedQuestions[question.id]}
              onChange={(checked) =>
                toggleAutoInsertQuestion(checked, question.id, question.autoInsert?.id)
              }
              marginBottom={16}
            />
          )}
        </RenderedComponent>

        {!adminView && isRejected && (
          <Body1Light marginTop={12} color={COLORS.red}>
            {isRejected}
          </Body1Light>
        )}

        {adminView && addRejectedQuestion && (
          <AdminRejectButton
            questionId={question.id}
            addRejectedQuestion={addRejectedQuestion}
            rejected={isRejected}
            questionnaireId={questionnaireId}
            disabled={!ENABLED_QUESTS_TO_INTERACT_STATUSES_ADMIN[questionnaireStatus]}
          />
        )}
      </Container>
    );
  }

  return null;
};

const AdminRejectButton = ({ questionId, rejected, addRejectedQuestion, disabled }) => {
  const { id, questionnaireId } = useParams();
  const [isModalActive, setModalActive] = useState(false);
  const [comment, setComment] = useState('');
  const [error, setError] = useState('');
  const { mutate: rejectQuestion } = useMutation((comment) =>
    adminKYCApi.setQuestionnaireStatus({
      investorId: id,
      status: 'UNACCEPTED',
      questionId: questionId,
      questionnaireId: questionnaireId,
      comment: comment
    })
  );

  const onReject = async () => {
    try {
      if (comment) {
        await rejectQuestion(comment);
        addRejectedQuestion(questionId);
        setModalActive(false);
      } else {
        setError('Required');
      }
    } catch (e) {
      alert(e?.message ? `Error: ${e?.message}` : 'Something went wrong');
      console.error(e);
    }
  };

  return (
    <Container marginTop={GU}>
      <RejectButton
        label={rejected ? 'Rejected' : 'Reject question'}
        onClick={() => setModalActive(true)}
        disabled={disabled || rejected}
      />

      <RejectModal
        active={isModalActive}
        onClose={() => setModalActive(false)}
        comment={comment}
        setComment={setComment}
        error={error}
        onSubmit={onReject}
      />
    </Container>
  );
};

const FormatRadioGroupForm = ({ question, adminView, required, ...props }) => {
  const { availableAnswers, answers, ...questionRest } = question;

  return (
    <div>
      {question.question && <Body2Light marginBottom={12}>
        {question.question}
        {required && <Span color={COLORS.aragonBlue}>&nbsp;*</Span>}
      </Body2Light>}

      <RadioGroupForm
        items={availableAnswers ? availableAnswers.map((item) => item.answer) : []}
        ids={availableAnswers ? availableAnswers.map((item) => item.id) : []}
        {...questionRest}
        {...props}
      />
    </div>
  );
};

const RenderFileView = ({ label, form }) => {
  const nameArray = form.name.split('.');

  const getValue = (values, nameArray) => {
    if (values) {
      return (
        nameArray.length === 1 ? values[nameArray[0]]
          : nameArray.reduce((result, current) => result[current], values)
      )
    }

    return null;
  };

  try {
    return (
      <div>
        {label && <Label2>{label}</Label2>}
        <AttachedFile url={getValue(form?.control['_formValues'], nameArray)} />
      </div>
    );
  } catch (e) {
  }
};

const getRenderedComponent = (type, isAdmin) => {
  const component = isAdmin ? adminComponentByType[type] : componentByType[type];
  return component || FormInput;
};
const componentByType = {
  EMAIL: EmailInput,
  PHONE: PhoneInput,
  INPUT: FormInput,
  NUMBER: (props) => <FormInput {...props} clearRegex={/[^0-9]/g} />,
  COUNTRY: CountryInput,
  ADDRESS: AddressBox,
  RADIO: FormatRadioGroupForm,
  CHECKBOX: CheckboxGroupForm,
  CONFIRMATIONS: CheckboxGroupForm,
  DOC_UPLOAD: FileUploadForm,
  RECORD_VIDEO: RecordVideoForm,
  DATE: FormDatePicker,
  SIGNATURE: SignatureForm
};
const adminComponentByType = {
  EMAIL: FormInput,
  PHONE: FormInput,
  INPUT: FormInput,
  NUMBER: FormInput,
  COUNTRY: FormInput,
  ADDRESS: AddressBox,
  RADIO: FormatRadioGroupForm,
  CHECKBOX: CheckboxGroupForm,
  CONFIRMATIONS: CheckboxGroupForm,
  DATE: FormDatePicker,
  SIGNATURE: RenderFileView,
  DOC_UPLOAD: RenderFileView,
  RECORD_VIDEO: RenderFileView
};
