import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GU } from '@aragon/ui';
import { InvestorsTable, SearchInput, TableBoxContainer } from '../../../../components';
import { Row, Col, DropDown, ExportButton } from '../../../../ui';
import {
  INVESTOR_STATUS_DESCRIPTION,
  INVESTOR_STATUS_TAB_VALUE,
  INVESTOR_STATUS_TABS,
  INVESTOR_TYPES,
  INVESTOR_TYPES_CONFIG, IS_IN_REVIEW_TAB_VALUE, LEGAL_STATUS_DESCRIPTION, LEGAL_STATUS_TABS, STATUS_TAB_VALUE
} from '../../../../utils/static';

const TAB_DESCR = {
  PERSONAL: INVESTOR_STATUS_DESCRIPTION,
  COMPANY: LEGAL_STATUS_DESCRIPTION
};
export const InvestorsTableBox = ({
  investors,
  updateSearchParams,
  INVESTORS_ON_PAGE,
  selectedTab
}) => {
  const { type } = useParams();
  const [search, setSearch] = useState('');
  const [selectedPage, setSelectedPage] = useState(0);
  // const [type, setType] = useState(0);

  useEffect(() => {
    if (search) setSearch('');
    if (selectedPage) setSelectedPage(0);
    refetchInvestors({searchValue: ''})
  }, [type]);

  const refetchInvestors = ({ searchValue = search, typeValue = type, skip = 0 }) => {
    updateSearchParams({
      search: searchValue,
      accountStatus: STATUS_TAB_VALUE[selectedTab],
      inReview: IS_IN_REVIEW_TAB_VALUE[selectedTab],
      account: typeValue,
      skip: skip
    });
  };

  //onUpdateSelectedTab
  useEffect(() => {
    onUpdateSearch();
  }, [selectedTab]);

  //onUpdateSearch
  const onUpdateSearch = () => {
    refetchInvestors({});
    setSelectedPage(0);
  };
  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      onUpdateSearch();
    }
  };

  //onUpdateType
  const onUpdateType = (type) => {
    refetchInvestors({ typeIndex: type });
    // setType(type);
    setSelectedPage(0);
  };

  //onUpdatePage
  const onUpdatePage = (page) => {
    refetchInvestors({ skip: page * INVESTORS_ON_PAGE });
    setSelectedPage(page);
  };

  if (investors) {
    const descriptions = TAB_DESCR[type] || INVESTOR_STATUS_TABS;

    return (
      <TableBoxContainer
        title={descriptions[selectedTab]}
        table={<InvestorsTable data={investors?.investors} />}
        // aside={<ExportButton/>}

        paginationSettings={{
          visible: investors.count > INVESTORS_ON_PAGE,
          selected: selectedPage,
          pages: Math.ceil(investors.count / INVESTORS_ON_PAGE),
          onChange: (item) => onUpdatePage(item)
        }}
        innerBoxProps={{ paddingBottom: 2 * GU }}
      >
        <Row margin={-GU} marginY={2 * GU}>
          <Col auto minWidth={'304px'}>
            <SearchInput
              value={search}
              onChange={setSearch}
              onBlur={onUpdateSearch}
              onKeyDown={handleSearchKeyDown}
              placeholder={'Search'}
              wide
            />
          </Col>

          {/*<Col auto minWidth={'164px'}>*/}
          {/*  <DropDown*/}
          {/*    items={['All types', ...INVESTOR_TYPES.map(item => INVESTOR_TYPES_CONFIG[item].title)]}*/}
          {/*    selected={type}*/}
          {/*    onChange={onUpdateType}*/}
          {/*    wide*/}
          {/*  />*/}
          {/*</Col>*/}
        </Row>
      </TableBoxContainer>
    );
  }

  return null;
};
