import React, { useEffect, useState } from 'react';
import { RegContainer, StepsPanel, FooterButtons } from '../../../../components';
import styled from 'styled-components';
import { Input, LinkButton, Title2 } from '../../../../ui';
import { GU } from '@aragon/ui';
import { individualSteps } from '../../../../utils/static';
import { useDispatch, useSelector } from 'react-redux';
import { investorApi, sendPhoneCode, verifyPhone } from '../../../../api/investor';
import { investorActions } from '../../../../store/investor';
import { entitiesActions } from '../../../../store/entities';
import {userActions, userReducer} from '../../../../store/user';
import { kycApi } from '../../../../api/kyc';

export const Step3 = ({ stepNumber, goBack, goNext }) => {
  const dispatch = useDispatch();
  const { phone } = useSelector((state) => state.investor);
  const [code, setCode] = useState('');

  useEffect(() => {
    handleSendCode();
  }, []);

  const handleSendCode = () => {
    sendPhoneCode().then(console.log);
  };

  const handleNext = async () => {
    try {
      dispatch(entitiesActions.showLoader('Processing...'));

      const res = await verifyPhone(code);

      if (res.status === 200) {
        await investorApi.createPersonalAccount();
        kycApi.createVerificationInstance();
        dispatch(investorActions.resetInvestor());
        dispatch(userActions.setRole('investor'));
        dispatch(userReducer.setShowUserGuide(true));
      }
    } catch (e) {
      console.log('verifyPhone', e);
      const errorMessage = e.response.data.message;
      if (errorMessage === 'CONFIRM_CODE_IS_WRONG') {
        dispatch(entitiesActions.addToast('Wrong code'));
      }
    } finally {
      dispatch(entitiesActions.hideLoader());
    }
  };

  return (
    <StepsPanelContainer>
      <StepsPanel title={'Registration'} step={stepNumber} steps={individualSteps} />

      <RegContainer type='centered'>
        <Title onClick={goNext}>Verify your phone number</Title>

        <Subtitle>
          The verification code has been sent on your phone number:
          <br />
          <LinkButton>{phone}</LinkButton>
        </Subtitle>

        <Input
          label={'Verification code'}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          type={'number'}
        />

        <RepeatSendTitle>
          <span>Didn’t recieve the code? </span>
          <LinkButton onClick={handleSendCode}>Try again</LinkButton>
        </RepeatSendTitle>

        <FooterButtons
          NextButtonTitle={'Send'}
          goBack={goBack}
          onNext={handleNext}
          withoutArrow
          nextDisabled={!code}
          hideBack
        />
      </RegContainer>
    </StepsPanelContainer>
  );
};

const StepsPanelContainer = styled.div`
  display: flex;
`;
const Title = styled(Title2)`
  font-weight: 300;
  margin-bottom: ${3 * GU}px;
`;
const Subtitle = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: #212b36;
  margin-bottom: 62px;
`;
const RepeatSendTitle = styled.p`
  margin: ${GU * 5}px 0 ${GU * 8}px 0;
`;
