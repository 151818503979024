import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { kycApi } from '../../../../../api/kyc';
import { SidePanel } from '@aragon/ui';
import { Container } from '../../../../../ui/layout';
import { DropDown, Button } from '../../../../../ui/atoms';
import styled from 'styled-components';
import { entitiesActions } from '../../../../../store/entities';
import { useDispatch } from 'react-redux';
import { adminKYCApi } from '../../../../../api/admin/kyc';
import { Body2Light } from '../../../../../ui/typography';

export const AddDocModal = ({ visible, onClose, onSuccess, adminView = false, investorId }) => {
  const dispatch = useDispatch();
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const { data: sections } = useQuery(
    ['getDocumentsSections'],
    adminView ? () => adminKYCApi.getDocumentsSections(investorId) : kycApi.getDocumentsSections
  );
  const { data: types, mutate: fetchTypes } = useMutation(
    adminView ? adminKYCApi.getDocumentSectionTypes : kycApi.getDocumentSectionTypes
  );
  const { mutateAsync: requestDocument } = useMutation(
    adminView ? adminKYCApi.addDocumentRequest : kycApi.requestDocument
  );

  const docsSectionsList = useMemo(() => (sections ? sections.data : null), [sections]);
  const docsTypesList = useMemo(() => (types ? types.data : null), [types]);

  useEffect(async () => {
    try {
      if (typeof selectedSection === 'number') {
        await fetchTypes({
          sectionId: docsSectionsList[selectedSection]?.id,
          investorId: investorId
        });
        setSelectedType(null);
      }
    } catch (e) {
      console.error('FETCH_DOCS_TYPES', e);
    }
  }, [selectedSection]);

  const onRequestDoc = async () => {
    try {
      await requestDocument({ docTypeId: docsTypesList[selectedType].id, investorId: investorId });
      if (onSuccess) await onSuccess();
      dispatch(entitiesActions.addToast('Successfully added'));
      setSelectedSection(null);
      setSelectedType(null);
      onClose();
    } catch (e) {
      console.error(e);
      dispatch(entitiesActions.addToast('Something went wrong'));
    }
  };

  console.log(docsTypesList);

  return (
    <SidePanel opened={visible} onClose={onClose} padding={32}>
      <PanelContainer padding={32}>
        <DropDown
          label={'Section'}
          items={docsSectionsList ? docsSectionsList.map((item) => item.name) : []}
          selected={selectedSection}
          onChange={setSelectedSection}
          disabled={!docsSectionsList}
        />
        <DropDown
          label={'Type'}
          items={docsTypesList ? docsTypesList.map((item) => item.name) : []}
          selected={selectedType}
          onChange={setSelectedType}
          disabled={!docsTypesList || docsTypesList.length === 0}
          marginTop={24}
        />
        {docsTypesList && docsTypesList.length === 0 && (
          <Body2Light marginTop={12}>Not allowed types to add</Body2Light>
        )}

        <Container marginTop={24}>
          <Button
            label={'Add'}
            onClick={onRequestDoc}
            mode={'strong'}
            disabled={typeof selectedType !== 'number'}
          />
        </Container>
      </PanelContainer>
    </SidePanel>
  );
};

const PanelContainer = styled(Container)`
  text-align: center;
`;
