import OptionCard from './registrationComponents/OptionCard';
import RegContainer from './registrationComponents/RegContainer';
import StepsPanel from './registrationComponents/StepsPanel/StepsPanel';
import TabsOwners from './registrationComponents/Tabs/TabsOwners';
import TabsWithInput from './registrationComponents/Tabs/TabsWithInput';
import AssetsTable from './investorComponents/AssetsTable/index';
import AssetsAvailableAssetsTable from './investorComponents/AssetsAvailableAssetsTable/index';
import ParamText from './investorComponents/ParamText/index';
import WishlistItem from './investorComponents/WishlistItem/index';
import ZoomImage from './investorComponents/ZoomImage/index';
import MoreBtn from './investorComponents/MoreBtn';
import ImageUpload from './registrationComponents/ImageUpload';
import FileUpload from './registrationComponents/FileUpload';
import { DocumentUpload } from './investorComponents/DocumentUpload';
export { FooterButtons } from './registrationComponents/FooterButtons';
export * from './customAragon';
export * from './common';
export * from './investor';
export * from './admin';

export {
  OptionCard,
  RegContainer,
  StepsPanel,
  AssetsTable,
  AssetsAvailableAssetsTable,
  ParamText,
  WishlistItem,
  ZoomImage,
  TabsOwners,
  MoreBtn,
  TabsWithInput,
  ImageUpload,
  DocumentUpload,
  FileUpload
};
