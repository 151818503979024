import { API_URL, ORDER_STATUS_TABS, ORDER_TAB_STATUSES } from '../../../utils/static';
import { ERRORS } from '../../static/errors';

export const newObject = (obj, key, value) => {
  let copy = Object.assign({}, obj);
  copy[key] = value;
  return copy;
};

export const changeObjectState = (dispatcher, prevState, key, value) => {
  dispatcher(newObject(prevState, key, value));
};

export const getFileSrc = (url) => {
  return `${API_URL}/${url}`;
};

export const getFilenameFromUrl = (url) => {
  const nameParts = url.split('-');
  const filteredParts = nameParts.filter((item, index) => index > nameParts.length - 2);
  return filteredParts.join('-');
};

export const getUrlName = (url, index) => {
  try {
    const array = url.split('/');
    const array2 = array[array.length - 1].split('-');
    return array2[array2.length - 1];
  } catch (e) {
    return `File ${index}`;
  }
};

const addZero = (val) => {
  return `0${val}`.slice(-2);
};

export const dateString = (date) => {
  if (date)
    return `${addZero(date.getDate())}.${addZero(date.getMonth() + 1)}.${date.getFullYear()}`;

  return '';
};

export const dateFromDatePicker = (date) => {
  if (date) {
    const formattedDate = new Date(date.replace(/^"(.*)"$/, '$1'));
    return `${addZero(formattedDate.getDate())}.${addZero(
      formattedDate.getMonth() + 1
    )}.${formattedDate.getFullYear()}`;
  }

  return '';
};

export const displayFullDate = (date, withoutTime = false) => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();

  return `${day}\u00a0${monthNames[month]}\u00a0${year}${
    withoutTime ? '' : ` ${addZero(hours)}:${addZero(minutes)}`
  }`;
};

export const getOrdersTabStatuses = (selectedTab) =>
  ORDER_TAB_STATUSES[ORDER_STATUS_TABS[selectedTab]];

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const formatAddress = (name, address) => {
  const formattedAddress = {};
  for (const [key, value] of Object.entries(address)) {
    formattedAddress[`${name}${capitalize(key)}`] = value;
  }

  return formattedAddress;
};

export const isObjectEmpty = (obj) => {
  for (let i in obj) return false;
  return true;
};

export const objectToFormData = (obj) => {
  let formData = new FormData();
  Object.keys(obj).forEach((key) => {
    formData.append(key, obj[key]);
  });

  return formData;
};

export const getErrorMessage = (err) => {
  const defaultError = 'Something went wrong';
  try {
    return ERRORS[err.response.data.message] || defaultError;
  } catch {
    return defaultError;
  }
};
