import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { kycApi } from '../../../api/kyc';
import { DocRequestView } from '../../../components';
import { useDispatch } from 'react-redux';
import { entitiesActions } from '../../../store/entities';
import { adminKYCApi } from '../../../api/admin/kyc';

export const InvestorDocRequest = () => {
  const { id, requestId } = useParams();
  const dispatch = useDispatch();
  const { data, refetch, isFetching } = useQuery(['getDocumentRequest'], () =>
    adminKYCApi.getDocumentRequest({ investorId: id, requestId })
  );

  useEffect(() => {
    isFetching
      ? dispatch(entitiesActions.showLoader('Loading...'))
      : dispatch(entitiesActions.hideLoader());
  }, [isFetching]);

  const approveRequest = async (docId) => updateRequestStatus(docId, 'ACCEPTED');
  const rejectRequest = async (docId) => updateRequestStatus(docId, 'UNACCEPTED');
  const updateRequestStatus = async (docId, status) => {
    try {
      dispatch(entitiesActions.showLoader(status === 'ACCEPTED' ? 'Approving...' : 'Rejecting...'));
      await adminKYCApi.updateRequestStatus({ kycDocumentId: docId, status });
      await refetch();
    } catch (e) {
      console.error(e);
      dispatch(entitiesActions.addToast('Something went wrong'));
    } finally {
      dispatch(entitiesActions.hideLoader());
    }
  };

  return (
    <DocRequestView
      isFetching={isFetching}
      data={data}
      refetch={refetch}
      requestId={requestId}
      investorId={id}
      viewMode={'admin'}
      approveRequest={approveRequest}
      rejectRequest={rejectRequest}
    />
  );
};
