import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InvestorsView } from '../View';
import { adminApi } from '../../../../../api/admin';
import { useDispatch } from 'react-redux';
import { entitiesActions } from '../../../../../store/entities';

const INVESTORS_ON_PAGE = 5;
export const Investors = () => {
  const { type } = useParams();
  const dispatch = useDispatch();

  const [investors, setInvestors] = useState([]);

  const fetchInvestors = (params = {}) => {
    dispatch(entitiesActions.showLoader('Fetching...'));

    adminApi
      .getInvestors({
        take: INVESTORS_ON_PAGE,
        reverse: true,
        account: type,
        ...params
      })
      .then((res) => {
        if (res.status === 200) {
          setInvestors(res.data);
        }
      })
      .finally(() => dispatch(entitiesActions.hideLoader()))
      .catch((e) => {
        console.error(e);
        dispatch(entitiesActions.addToast('Something went wrong'));
      });
  };

  // useEffect(() => {
  //   fetchInvestors();
  // }, [type]);

  return (
    <InvestorsView
      investors={investors}
      fetchInvestors={fetchInvestors}
      INVESTORS_ON_PAGE={INVESTORS_ON_PAGE}
      type={type}
    />
  );
};
