import React, { useMemo, useState } from 'react';
import { Modal } from '@aragon/ui';
import { Col, Container, Hr, PageContainer, Row } from '../../../../ui/layout';
import { BackButton } from '../../../../ui/atoms/BackButton';
import { history } from '../../../../routing';
import { Box } from '../../../customAragon/Box';
import {Body1Light, Body2Light, Text, Title4} from '../../../../ui/typography';
import { COLORS } from '../../../../utils/static/colors';
import {
  ENABLED_DOC_STATUSES_ADMIN,
  ENABLED_STATUSES_INVESTOR,
  STATUS_DISPLAY
} from '../../../../utils/static/kyc';
import { dateString, getFileSrc } from '../../../../utils/helpers/common';
import { Button } from '../../../../ui/atoms/Button';
import { ExternalLink } from '../../../../ui/atoms/Link';
import { useMutation } from 'react-query';
import { kycApi } from '../../../../api/kyc';
import { UploadDocumentsBox } from '../../InputBoxes/UploadDocuments';
import styled from 'styled-components';
import { entitiesActions } from '../../../../store/entities';
import { adminKYCApi } from '../../../../api/admin/kyc';
import { Input } from '../../../../ui/atoms/Input';
import { useDispatch, useSelector } from 'react-redux';

export const DocRequestView = ({
  isFetching,
  data,
  refetch,
  requestId,
  investorId,
  viewMode = 'investor',
  approveRequest,
  rejectRequest
}) => {
  const { investor } = useSelector((state) => state);
  const [modalVisible, setModalVisible] = useState(false);
  const [addCommentToId, setAddCommentToId] = useState(-1);

  const requestInfo = useMemo(() => {
    if (data && data.data) {
      const { kycDocuments, ...requestObj } = data.data;
      return {
        ...requestObj,
        files:
          kycDocuments && kycDocuments.length > 0
            ? kycDocuments.map((file) => ({
                id: file.id,
                comment: file.comments[0] ? file.comments[0].comment : '',
                file: getFileSrc(file.documentURL)
              }))
            : [{}]
      };
    }

    return {};
  }, [data]);

  const onBack = () => {
    viewMode === 'admin'
      ? history.push(`/investor/${investorId}`, {lastProfileTab: investor.lastProfileTab})
      : history.push('/profile', {lastProfileTab: investor.lastProfileTab});
  };

  const currentDocId = useMemo(() => {
    try {
      return requestInfo.files[0]?.id;
    } catch (e) {}

    return null;
  }, [requestInfo]);

  return (
    <StyledPageContainer paddingTop={24}>
      <Container marginBottom={32}>
        <BackButton onClick={onBack} />
      </Container>

      {!isFetching && requestInfo && (
        <Box heading={requestInfo.kycTypeDocument?.title} headingSize={14} headingPaddingY={10}>
          <Row marginBottom={32}>
            <Col>
              <Body1Light>
                Status:{' '}
                <Text
                  color={COLORS.aragonBlue}
                  inline
                  fontWeight={400}
                >
                  {requestInfo.status ? STATUS_DISPLAY[requestInfo.status] : '-'}
                </Text>
              </Body1Light>

              <Body1Light marginTop={10}>
                Created:{' '}
                <Text inline>
                  {requestInfo.created ? dateString(new Date(requestInfo.created)) : '-'}
                </Text>
              </Body1Light>
              <Body1Light>
                Updated:{' '}
                <Text inline>
                  {requestInfo.updated ? dateString(new Date(requestInfo.updated)) : '-'}
                </Text>
              </Body1Light>
              <Body1Light>
                Expiration date:{' '}
                <Text inline>
                  {requestInfo.expiration ? dateString(new Date(requestInfo.expiration)) : '-'}
                </Text>
              </Body1Light>
            </Col>

            <Col auto>
              {viewMode === 'investor' && (
                <Button
                  label={'Upload file'}
                  onClick={() => setModalVisible(true)}
                  mode={'strong'}
                  size={'small'}
                  disabled={
                    investor.accountStatus !== 'ACTIVE' ||
                    (requestInfo.status && !ENABLED_STATUSES_INVESTOR[requestInfo.status])
                  }
                />
              )}
              {viewMode === 'admin' && (
                <Row margin={-8}>
                  <Col auto>
                    <Button
                      label={'Approve'}
                      onClick={() => approveRequest(currentDocId)}
                      mode={'strong'}
                      size={'small'}
                      disabled={!ENABLED_DOC_STATUSES_ADMIN[requestInfo.status]}
                    />
                  </Col>
                  <Col auto>
                    <Button
                      label={'Reject'}
                      onClick={() => rejectRequest(currentDocId)}
                      size={'small'}
                      disabled={!ENABLED_DOC_STATUSES_ADMIN[requestInfo.status]}
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>

          {requestInfo.files.map((file, index) => (
            <Row margin={-16} marginBottom={8} justifyContent={'space-between'}>
              <Body1Light fontWeight={400}>
                Attached file:&nbsp;
                {file.file ? (
                  <ExternalLink href={file.file} external>
                    File{requestInfo.files.length - index}
                  </ExternalLink>
                ) : (
                  '-'
                )}
              </Body1Light>
              <Col auto>
                {file.comment && <Body2Light color={COLORS.red}>{file.comment}</Body2Light>}
                {viewMode === 'admin' && file.file && !file.comment && (
                  <Button
                    label={'New comment'}
                    onClick={() => setAddCommentToId(file.id)}
                    size={'small'}
                    mode={'strong'}
                  />
                )}
              </Col>
            </Row>
          ))}

          {requestInfo.kycTypeDocument?.descriptions && (
            <Container marginTop={32}>
              <Body1Light marginBottom={8}>File description</Body1Light>
              <Body2Light>{requestInfo.kycTypeDocument?.descriptions}</Body2Light>
            </Container>
          )}
        </Box>
      )}

      <UploadFileModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        refetchRequest={refetch}
        requestId={requestId}
      />
      <AddCommentModal
        visible={addCommentToId !== -1}
        onClose={() => setAddCommentToId(-1)}
        refetchRequest={refetch}
        docId={addCommentToId}
      />
    </StyledPageContainer>
  );
};

const AddCommentModal = ({ visible, onClose, docId, refetchRequest }) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');

  const addDocComment = async () => {
    try {
      dispatch(entitiesActions.showLoader('Saving...'));
      await adminKYCApi.addRequestComment({ kycDocumentId: docId, comment });
      await refetchRequest();
      onClose();
    } catch (e) {
      console.error(e);
      dispatch(entitiesActions.addToast('Something went wrong'));
    } finally {
      dispatch(entitiesActions.hideLoader());
    }
  };

  return (
    <Modal visible={visible} onClose={onClose} padding={48}>
      <Input label={'Comment'} value={comment} onChange={(e) => setComment(e.target.value)} />
      <Container marginTop={16} style={{ textAlign: 'center' }}>
        <Button label={'Add comment'} onClick={addDocComment} mode={'strong'} />
      </Container>
    </Modal>
  );
};
const UploadFileModal = ({ visible, onClose, requestId, refetchRequest }) => {
  const [file, setFile] = useState(null);
  const { mutateAsync } = useMutation(kycApi.uploadDocument);

  const onUpload = async () => {
    try {
      await mutateAsync({ requestDocumentId: requestId, file: file });
      await refetchRequest();
      onClose();
      setFile(null);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal visible={visible} onClose={onClose} padding={48}>
      <UploadDocumentsBox
        docs={file ? [file] : null}
        setDocs={(file) => setFile(file)}
        buttonProps={{
          label: 'Pick file'
        }}
      />

      <Container marginTop={24} style={{ textAlign: 'center' }}>
        <Button label={'Upload'} onClick={onUpload} mode={'strong'} disabled={!file} />
      </Container>
    </Modal>
  );
};

const StyledPageContainer = styled(PageContainer)`
  padding-bottom: 32px;
  max-width: 1200px;
  margin: 0 auto;
`;
