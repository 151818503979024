import React, { useRef } from 'react';
import { IconPlus } from '@aragon/ui';
import { Button, Container } from '../../../../ui';

export const FileUploadButton = ({ multiple = false, onChange, buttonProps, ...props }) => {
  const myRef = useRef();

  const handleChange = (e) => {
    if (e.target.files.length) {
      onChange(e.target.files);
      myRef.current.value = '';
    }
  };

  const handleClick = () => {
    myRef.current.click();
  };

  return (
    <Container {...props}>
      <Button
        onClick={handleClick}
        label={'Choose File'}
        icon={<IconPlus size={'small'} />}
        paddingRight={'16'}
        paddingLeft={'11'}
        fontWeight={300}
        {...buttonProps}
      />

      <input
        type='file'
        ref={myRef}
        style={{ display: 'none' }}
        onChange={handleChange}
        multiple={multiple}
      />
    </Container>
  );
};
