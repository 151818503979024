import React from "react";
import styled from "styled-components";
import {Col, Row} from "../../../../ui/layout";
import {Body1Light, Title4} from "../../../../ui/typography";
import telegramIcon from '../../../../assets/icons/telegram_icon.svg';
import whatsappIcon from '../../../../assets/icons/whatsapp_icon.svg';
import {ExternalLink} from "../../../../ui/atoms/Link";


export const Footer = () => {


  return (
    <FooterWrapper>
      <FooterContainer>
        <Row margin={-16} justifyContent={'center'} alignCenter>
          <Body1Light>Contact us:</Body1Light>

          <Col auto>
            <Row margin={-8} alignCenter>
              <ExternalLink href={'mailto:compliance@casecapital.co.il'} external>
                compliance@casecapital.co.il
              </ExternalLink>
              <ExternalLink href={'tel:972583459040'} external>
                +972 58 3459040
              </ExternalLink>

              <Col auto>
                <a href={'https://t.me/DavidSkorohod'} target={'_blank'}>
                  <TelegramIcon src={telegramIcon}/>
                </a>
              </Col>
              <Col auto>
                <a href={'https://api.whatsapp.com/send?phone=972545954925'} target={'_blank'}>
                  <WhatsappIcon src={whatsappIcon}/>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </FooterContainer>
    </FooterWrapper>
  )
};


const FooterWrapper = styled.footer`
  background: white;
  padding: 16px 24px 16px 16px;
  min-height: 60px;
  box-shadow: 0px -1px 3px rgb(0 0 0 / 15%);
  margin-top: 20px;
`;
const FooterContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto
`;
const TelegramIcon = styled.img`
   width: 26px;
   height: 26px;
   display: block;
`;
const WhatsappIcon = styled(TelegramIcon)``;
