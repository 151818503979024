import React, {useMemo} from 'react';
import { Box } from '../../../customAragon';
import {Body2Light, Body3Light, Button, Col, Row, Title4, Container, Text, Title5} from '../../../../ui';
import styled from 'styled-components';
import { COLORS } from '../../../../utils/static';
import { displayFullDate } from '../../../../utils/helpers/common';
import {
  DISPLAY_SUMSUB_REJECT_STATUS,
  STATUS_BUTTON_MODE,
  STATUS_COLOR,
  STATUS_DISPLAY
} from "../../../../utils/static/kyc";
import {history} from "../../../../routing";
import { useLocation } from 'react-router-dom';

export const QuestBox = (
  {
    data,
    number,
    title = '',
    status = '',
    error = '',
    enabled,
    onClick,
    ...props
  }) => {
  const boxTitle = title || data?.name;
  const boxStatus = status || data?.status;
  const boxEnabled = enabled || data?.enabled;
  const boxError = error || data?.error;

  return (
    <StyledBox {...props} active={boxEnabled} bgColor={STATUS_COLOR[boxStatus]}>
      <Row margin={-7} nowrap height={'100%'}>
        {number && (
          <Col auto>
            <NumberCircle active={boxEnabled}>{number}</NumberCircle>
          </Col>
        )}
        <Col auto style={{ overflow: 'hidden' }}>
          <Row flexDirection={'column'} justifyContent={'space-between'} nowrap height={'100%'}>
            <Col>
              <Title weight={300} color={COLORS.default} marginBottom={16}>
                {boxTitle}
              </Title>
            </Col>

            <Container>
              {boxError && (
                <Body3Light marginBottom={10}>
                  Comment:{' '}
                  <Text color={COLORS.red} fontWeight={600} textInline>
                    {boxError}
                  </Text>
                </Body3Light>
              )}

              <Body2Light>
                Status:{' '}
                <Text color={STATUS_COLOR[boxStatus] || COLORS.aragonBlue} textInline fontWeight={700}>
                  {status || STATUS_DISPLAY[boxStatus]}
                </Text>
              </Body2Light>
              {data?.person && (<Body2Light>
                Person:{' '}
                <Text textInline fontWeight={400}>
                  {data.person}
                </Text>
              </Body2Light>)}

              <Body2Light marginTop={10}>
                Created:{' '}
                <Text textInline fontWeight={400}>
                  {data?.created ? displayFullDate(new Date(data?.created)) : '-'}
                </Text>
              </Body2Light>
              <Body2Light>
                Updated:{' '}
                <Text textInline fontWeight={400}>
                  {data?.updated ? displayFullDate(new Date(data?.updated)) : '-'}
                </Text>
              </Body2Light>
              <Body2Light>
                Expiration date:{' '}
                <Text textInline fontWeight={400}>
                  {data?.expiration ? displayFullDate(new Date(data?.expiration)) : '-'}
                </Text>
              </Body2Light>

              <Container marginTop={16}>
                <Button
                  label={'Open'}
                  onClick={onClick}
                  size={'small'}
                  mode={STATUS_BUTTON_MODE[boxStatus] || 'strong'}
                  fontWeight={300}
                  disabled={!boxEnabled}
                />
              </Container>
            </Container>
          </Row>
        </Col>
      </Row>
    </StyledBox>
  );
};


export const SumSubBox = (
  {
    data,
    number,
    title = 'Verification',
    error = '',
    enabled,
    onClick,
    investor,
    adminView,
    ...props
  }) => {
  const location = useLocation();
  const boxStatus = data?.status;
  const boxEnabled = investor.accountStatus === 'ACTIVE' && (adminView || !data?.isAnswered);
  const rejectLabels = useMemo(() => data?.reviewResult?.rejectLabels, [data]);
  const boxError = useMemo(() => {
    if (rejectLabels && rejectLabels.length > 0) {
      return rejectLabels.reduce((result, item) => {
        if (item && DISPLAY_SUMSUB_REJECT_STATUS[item]) {
          return result ? `${result}, ${DISPLAY_SUMSUB_REJECT_STATUS[item]}` : DISPLAY_SUMSUB_REJECT_STATUS[item];
        }

        return result;
      }, '');
    }

    return '';
  }, [rejectLabels]);

  const onOpen = () => {
    history.push(
      adminView
        ? `/investor/${investor.id}/sumsub-verification`
        : '/sumsub-verification',
      { prevPath: location.pathname, nextState: {lastProfileTab: investor.lastProfileTab} }
    )
  };

  console.log(data)

  return (
    <StyledBox {...props} active={boxEnabled} bgColor={STATUS_COLOR[boxStatus]}>
      <Row margin={-7} nowrap height={'100%'}>
        {number && (
          <Col auto>
            <NumberCircle active={boxEnabled}>{number}</NumberCircle>
          </Col>
        )}
        <Col auto style={{ overflow: 'hidden' }}>
          <Row flexDirection={'column'} justifyContent={'space-between'} nowrap height={'100%'}>
            <Col>
              <Title weight={300} color={COLORS.default} marginBottom={16}>
                {title}
              </Title>
            </Col>

            <Container>
              {boxError && (
                <Body3Light marginBottom={10}>
                  Comment:{' '}
                  <Text color={COLORS.red} fontWeight={600} textInline>
                    {boxError}
                  </Text>
                </Body3Light>
              )}

              <Body2Light>
                Status:{' '}
                <Text color={STATUS_COLOR[boxStatus] || COLORS.aragonBlue} textInline fontWeight={700}>
                  {boxStatus}
                </Text>
              </Body2Light>
              {data?.person && (<Body2Light>
                Person:{' '}
                <Text textInline fontWeight={400}>
                  {data.person}
                </Text>
              </Body2Light>)}

              <Body2Light marginTop={10}>
                Created:{' '}
                <Text textInline fontWeight={400}>
                  {data?.created ? displayFullDate(new Date(data?.created)) : '-'}
                </Text>
              </Body2Light>
              <Body2Light>
                Updated:{' '}
                <Text textInline fontWeight={400}>
                  {data?.updated ? displayFullDate(new Date(data?.updated)) : '-'}
                </Text>
              </Body2Light>
              <Body2Light>
                Expiration date:{' '}
                <Text textInline fontWeight={400}>
                  {data?.expiration ? displayFullDate(new Date(data?.expiration)) : '-'}
                </Text>
              </Body2Light>

              <Container marginTop={16}>
                <Button
                  label={'Open'}
                  onClick={onOpen}
                  size={'small'}
                  mode={STATUS_BUTTON_MODE[boxStatus] || 'strong'}
                  fontWeight={300}
                  disabled={!boxEnabled}
                />
              </Container>
            </Container>
          </Row>
        </Col>
      </Row>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  border-radius: 12px;
  overflow: hidden;
  height: 100%;
  // filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.12));
  // filter: drop-shadow(8px 8px 16px rgba(0, 0, 0, 0.08));
  filter: drop-shadow(8px 12px 16px rgba(0, 0, 0, 0.08));
  border: none;

  :after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 6px;
    width: 100%;
    // background: ${p => p.active ? COLORS.aragonBlue : '#F1F3F7'};
    background: ${p => p.bgColor ? p.bgColor : COLORS.aragonBlue};
  }
  
  > div,
  > div > div {
    height: 100%;
  }
`;
const Title = styled(Title5)`
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  font-weight: 400;
`;
const NumberCircle = styled.div`
  width: 24px;
  height: 24px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 14px;
  font-weight: 300;
  color: ${COLORS.white};
  background: ${(p) =>
  p.active ? 'linear-gradient(200.38deg, #32FFF5 -153.94%, #08BEE5 84.51%)' : COLORS.greyLight};
`;
