import React from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import { adminKYCApi, superAdminApi } from '../../api';
import { entitiesActions } from '../../store/entities';
import { Box, InnerBox, UploadFilesModal } from '../../components';
import { Col, Row } from '../../ui/layout';
import { Button } from '../../ui/atoms';
import { DeepCheckView } from './View';

export const DeepCheckModule = ({ investorId, viewMode }) => {
  const dispatch = useDispatch();
  const { data: deepCheckRes, refetch } = useQuery(
    ['adminKYCApi.getInvestorDeepCheck', investorId],
    () => adminKYCApi.getInvestorDeepCheck(investorId)
  );
  const { mutateAsync: mutateInitCheck } = useMutation(adminKYCApi.initInvestorDeepCheck);
  const { mutateAsync: mutateUploadReport } = useMutation(superAdminApi.uploadDeepCheckReport);

  const initCheck = async (type) => {
    try {
      await mutateInitCheck({ investorId, type });
      dispatch(entitiesActions.addToast('Sent'));
      await refetch();
    } catch (e) {
      console.error(e);
      dispatch(entitiesActions.addToast('Something went wrong'));
    }
  };

  const uploadReport = async ({ checkId, file }) => {
    try {
      await mutateUploadReport({ checkId, file });
      dispatch(entitiesActions.addToast('Upload'));
      await refetch();
    } catch (e) {
      console.error(e);
      dispatch(entitiesActions.addToast('Something went wrong'));
    }
  };

  return (
    <DeepCheckView
      viewMode={viewMode}
      deepCheckData={deepCheckRes?.data}
      initCheck={initCheck}
      uploadReport={uploadReport}
    />
  );
};
