import React, { useMemo, useRef, useState } from 'react';
import { GU } from '@aragon/ui';
import { DocumentBox } from '../../../../components';
import { Row, Col, Title4, Button, Container } from '../../../../ui';
import { COLORS } from '../../../../utils/static';
import {
  ENABLED_DOC_TO_VIEW_STATUSES_ADMIN,
  ENABLED_STATUSES_INVESTOR,
  STATUS_DISPLAY
} from '../../../../utils/static/kyc';
import styled from 'styled-components';
import { AddDocModal } from '../../../../modules/Questionnaire/components/modals/AddDoc';
import { history } from '../../../../routing';
import {getFileSrc, getUrlName} from '../../../../utils/helpers/common';
import { useSelector } from 'react-redux';

/**
 * @param documents (array of requested docs)
 * @param onDelete (func) - handle delete doc
 */
const enabledStatuses = {
  investor: ENABLED_STATUSES_INVESTOR,
  admin: ENABLED_DOC_TO_VIEW_STATUSES_ADMIN
};
export const ProfileDocuments = ({
  documents,
  questsDocs,
  refetchDocs,
  viewMode = 'investor',
  investorId
}) => {
  const { investor } = useSelector((state) => state);
  const [addDocVisible, setAddDocVisible] = useState(false);
  //array of sections
  const docsSections = useMemo(() => {
    try {
      const result = {};

      documents.forEach((doc) => {
        const sectionDocs = result[doc.kycTypeDocument.kycDocumentSectionId];
        const docStatus = doc.status || 'PENDING';
        const formattedDoc = {
          ...doc,
          enabled:
            (viewMode !== 'investor' || investor.accountStatus === 'ACTIVE') &&
            enabledStatuses[viewMode][docStatus]
        };

        sectionDocs
          ? result[doc.kycTypeDocument.kycDocumentSectionId].docs.push(formattedDoc)
          : (result[doc.kycTypeDocument.kycDocumentSectionId] = {
              name: doc.kycDocumentSection.name,
              docs: [formattedDoc]
            });
      });

      return Object.keys(result).map((key) => result[key]);
    } catch (e) {}

    return null;
  }, [documents]);
  const questsDocsFormatted = useMemo(() => {
    if (questsDocs) {
      return questsDocs.map((doc, index) => {
        const {status, created, updated, expiration, person} = doc.investorQuestionSectionTypes;
        const {name} = doc.questionSectionType;
        const questionName = doc.question?.question;

        return {
          ...doc,
          status, created, updated, expiration, person,
          name: name ? name : getUrlName(doc.documentURL, index + 1),
          description: questionName,
          enabled: true
        }
      });
    }

    return null;
  }, [questsDocs]);

  const onOpenDocument = ({ id, investorId }) => {
    switch (viewMode) {
      case 'investor': {
        history.push(`/documents/request/${id}`);
        break;
      }
      case 'admin': {
        history.push(`/investor/${investorId}/documents/request/${id}`);
        break;
      }
    }
  };

  return (
    <Container style={{ position: 'relative' }}>
      {docsSections &&
        docsSections.map((section, key) => (
          <DocsList
            title={section.name}
            list={section.docs}
            openDocument={onOpenDocument}
            key={key}
          />
        ))}
      {questsDocsFormatted && questsDocsFormatted.length > 0 && (
        <DocsList
          title={'Documents from QUESTIONNAIRES'}
          list={questsDocsFormatted}
          openDocument={(doc) => window.open(getFileSrc(doc.documentURL))}
        />
      )}

      <AddDocContainer>
        <Button
          label={'+'}
          onClick={() => setAddDocVisible(true)}
          paddingX={'20'}
          size={'small'}
          mode={'strong'}
          disabled={investor.accountStatus !== 'ACTIVE'}
        />
        <AddDocModal
          visible={addDocVisible}
          onClose={() => setAddDocVisible(false)}
          onSuccess={refetchDocs}
          adminView={viewMode === 'admin'}
          investorId={investorId}
        />
      </AddDocContainer>
    </Container>
  );
};

const DocsList = ({ title = '', list, openDocument }) => {
  if (list) {
    return (
      <DocsContainer>
        <Title4 marginBottom={12}>{title}</Title4>

        <Row cols={3} margin={-8} colsMarginBottom={2 * GU}>
          {list.map((item, index) => (
            <Col key={index}>
              <DocumentBox data={item} onClick={() => openDocument(item)} />
            </Col>
          ))}
        </Row>
      </DocsContainer>
    );
  }

  return null;
};

const DocsContainer = styled.div`
  margin-top: 50px;
`;
const AddDocContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
