import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { InvestorDocRequest } from '../../containers/admin';
import {
  Assets,
  Deals,
  Funds,
  Home,
  Investors,
  Issues,
  Management,
  Orders,
  Reporting,
  CreateIssue,
  CreateIssuer,
  InvestorDetails,
  AssetDetails,
  IssuerDetails,
  TransactionDetails,
  OrderDetails,
  QuestionnaireAnswers,
  SumsubAnswers
} from '../../pages/admin';

export const AdminRoutes = () => {
  return (
    <Switch>
      <Route exact path='/home' component={Home} />
      <Route exact path='/investors/:type' component={Investors} />
      <Route exact path='/investor/:id' component={InvestorDetails} />
      <Route
        exact
        path='/investor/:id/questionnaire/:questionnaireId'
        component={QuestionnaireAnswers}
      />
      <Route exact path='/investor/:id/sumsub-verification' component={SumsubAnswers} />
      <Route
        exact
        path='/investor/:id/documents/request/:requestId'
        component={InvestorDocRequest}
      />
      <Route exact path='/issues' component={Issues} />
      <Route exact path='/issuer/:id' component={IssuerDetails} />
      <Route exact path='/create-issue' component={CreateIssue} />
      <Route exact path='/create-issuer' component={CreateIssuer} />
      <Route exact path='/deals' component={Deals} />
      <Route exact path='/assets' component={Assets} />
      <Route exact path='/asset/:id' component={AssetDetails} />
      <Route exact path='/funds' component={Funds} />
      <Route exact path='/transaction/:id' component={TransactionDetails} />
      <Route exact path='/reporting' component={Reporting} />
      <Route exact path='/management' component={Management} />
      <Route exact path='/orders' component={Orders} />
      <Route exact path='/order/:id' component={OrderDetails} />

      <Redirect to={'/investors?type=PERSONAL'} />
    </Switch>
  );
};
