import React from 'react';
import { _AutoComplete, Text } from '@aragon/ui';
import { Body4Light, Label2 as InputLabel, Input } from '../../../ui';
import { COLORS } from '../../../utils/static';
import styled from 'styled-components';

export const AutoComplete = ({
  label,
  required,
  items,
  value,
  onChange,
  onSelect,
  error,
  disabled,
  ...props
}) => {
  return (
    <div>
      {label && (
        <InputLabel>
          {label}
          {required && <Text color={COLORS.aragonBlue}>&nbsp;*</Text>}
        </InputLabel>
      )}

      <RelativeBlock>
        {!disabled && <_AutoComplete
          value={value}
          onChange={onChange}
          onSelect={onSelect || onChange}
          items={
            items
              ? items.filter(
                  (name) => value && name.toLowerCase().indexOf(value.toLowerCase()) > -1
                )
              : []
          }
          {...props}
        />}
        {disabled && <Input
          value={value}
          disabled={disabled}
          onChange={onChange}
          {...props}
        />}

        {error && (
          <Error>
            <Body4Light color={COLORS.greyMedium}>{error}</Body4Light>
          </Error>
        )}
      </RelativeBlock>
    </div>
  );
};

const RelativeBlock = styled.div`
  position: relative;
`;
const Error = styled.div`
  position: absolute;
  text-align: left;
  bottom: calc(50% + 8px + 9px);
  right: 0;
  min-width: 167px;
  padding: 8px;
  background: ${COLORS.darkBabyBlue};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 3px;

  :after {
    content: '';
    position: absolute;
    right: 12px;
    bottom: -3px;
    width: 8px;
    height: 8px;
    background: ${COLORS.darkBabyBlue};
    border-radius: 2px;
    transform: rotate(45deg) skew(10deg, 10deg);
    box-shadow: 3px 3px 5px rgb(0 0 0 / 12%);
  }
`;
