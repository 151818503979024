import React, { useContext } from 'react';
import { ProfileReferralsView } from '../../../common/organisms';
import { useQuery } from 'react-query';
import { investorApi, investorKeys } from '../../../../api/investor';
import { MessageContext } from '../../../../app/MessageProvider';

export const ProfileReferrals = () => {
  const messageApi = useContext(MessageContext);
  const refferals = useQuery({
    queryKey: investorKeys.getRefferalAccounts,
    queryFn: investorApi.getMyRefferalAccounts,
    select: data1 => data1.data,
    onError: () => messageApi.error('Refferals fetch error'),
  });
  const reccomenders = useQuery({
    queryKey: investorKeys.getRecommendersAccounts,
    queryFn: investorApi.getMyRecommenderAccounts,
    select: data1 => data1.data,
    onError: () => messageApi.error('Reccomenders fetch error')
  });


  return (
    <ProfileReferralsView
      referrals={{ list: refferals.data, error: refferals.isError, isLoading: refferals.isLoading }}
      recommeders={{ list: reccomenders.data, error: reccomenders.isError, isLoading: reccomenders.isLoading }}
    />
  );
};
