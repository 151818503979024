import axios from 'axios';
import { API_URL } from '../../../utils/static/api';

export const getMyRefferalCode = () => (
  axios.get(`${API_URL}/api/kyc/v1/investor/referralCode`)
);

export const generateMyRefferalCode = () => (
  axios.post(`${API_URL}/api/kyc/v1/investor/referralCode`)
);

export const getMyRefferalAccounts = () => (
  axios.get(`${API_URL}/api/kyc/v1/recommender/investors`)
);

export const getMyRecommenderAccounts = () => (
  axios.get(`${API_URL}/api/kyc/v1/investor/recommenders`)
);

export const setRefferalCode = (code) => (
  axios.post(`${API_URL}/api/kyc/v1/investor/setReferralCode`, { referralCode: code })
);
