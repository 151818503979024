import React, { useMemo } from 'react';
import { useController } from 'react-hook-form';
import { PhoneInput as _PhoneInput } from '../../../../ui/atoms';
import { PHONE_CODES } from '../../../../utils/static/phoneCodes';

const DIVIDE_SYMBOL = '/';
export const PhoneInput = ({ form, label, required, ...props }) => {
  const {
    field: { ref, onChange, value, ...field },
    fieldState: { error }
  } = useController({
    ...form,
    rules: {
      ...form.rules,
      validate: {
        isCode: (v) => {
          if (!required && !v.replace(DIVIDE_SYMBOL, '')) return true;
          const dividerIndex = v.indexOf(DIVIDE_SYMBOL);
          const code = dividerIndex >= 0 ? v.slice(0, dividerIndex) : '';

          if (!code) return 'Enter country';
          if (code) {
            return (
              PHONE_CODES.filter((item) => item.code === code)[0] || 'Select phone code from list'
            );
          }
        },
        isNumber: (v) => {
          if (!required && !v.replace(DIVIDE_SYMBOL, '')) return true;
          const dividerIndex = v.indexOf(DIVIDE_SYMBOL);
          const number = dividerIndex >= 0 ? v.slice(dividerIndex + 1) : '';

          return number ? true : 'The number is required';
        }
      }
    },
    defaultValue: ''
  });

  const changeCode = (code) => {
    const dividerIndex = value.indexOf(DIVIDE_SYMBOL);
    const number = dividerIndex >= 0 ? value.slice(dividerIndex + 1) : '';
    onChange({ target: { value: `${code}${DIVIDE_SYMBOL}${number}` } });
  };
  const changeNumber = (e) => {
    const dividerIndex = value.indexOf(DIVIDE_SYMBOL);
    const code = dividerIndex >= 0 ? value.slice(0, dividerIndex) : '';
    onChange({ target: { value: `${code}${DIVIDE_SYMBOL}${e.target.value}` } });
  };

  const values = useMemo(() => {
    if (value) {
      const valuesArray = value.split(DIVIDE_SYMBOL);

      return {
        code: valuesArray.length > 1 ? valuesArray[0] : '',
        number: valuesArray.length > 1 ? valuesArray[1] : valuesArray[0]
      };
    }

    return { code: '', number: '' };
  }, [value]);

  return (
    <_PhoneInput
      {...field}
      {...props}
      required={required}
      rowLabel={label}
      code={values.code}
      value={values.number}
      setCode={changeCode}
      onChange={changeNumber}
      codeError={error && error.type === 'isCode' && error.message}
      error={error && error.type !== 'isCode' && error.message}
      clearRegex={/[^0-9]/g}
      ref={ref}
    />
  );
};
