import axios from 'axios';
import { API_URL } from '../../utils/static';

//by email
export const auth = (data) => {
  return axios.post(`${API_URL}/api/v1/auth`, data);
};

export const getToken = (data) => {
  return axios.post(`${API_URL}/api/v1/auth/token`, data);
};

export const resendEmailCode = (email) => {
  return axios.post(`${API_URL}/api/v1/resendEmailSms`, { email: email });
};

export const resetPasswordSendCode = (email) => {
  return axios.post(`${API_URL}/api/kyc/v1/investor/changePassword`, { email: email });
};
export const resetPassword = ({email, newPassword, code}) => {
  return axios.put(`${API_URL}/api/kyc/v1/investor/changePassword`, { email, newPassword, code });
};

//by metamask
export const getMessage = (ethereumAddress) => {
  return axios.post(`${API_URL}/api/v1/auth/message`, { ethereumAddress: ethereumAddress });
};

export const verifyMessage = (signature, ethereumAddress) => {
  return axios.post(`${API_URL}/api/v1/auth/verify`, {
    ethereumAddress: ethereumAddress,
    signature: signature
  });
};

export const authApi = {
  auth,
  getToken,
  resendEmailCode,
  resetPasswordSendCode,
  resetPassword,
  getMessage,
  verifyMessage
};
