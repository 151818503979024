import React, { useEffect, useState } from 'react';
import { Col, Row } from '../../layout';
import { AutoComplete, Input } from '../../atoms';
import {PHONE_CODES, PHONE_CODES_LIST} from '../../../utils/static';
import { Body2Light } from '../../typography';

export const PhoneInput = (
  {
    code,
    setCode,
    codeError,
    marginBottom,
    label = 'Phone number',
    rowLabel,
    required,
    disabled,
    ...props
  }) => {
  const [codeSearch, setCodeSearch] = useState('');

  useEffect(() => {
    if (code && !codeSearch) {
      const selectedCode = PHONE_CODES.filter((item) => item.code === code)[0];
      if (selectedCode) {
        setCodeSearch(`(${selectedCode.code}) ${selectedCode.country}`)
      }
    }
  }, []);

  useEffect(() => {
    const codeStart = codeSearch.indexOf('(');
    const codeEnd = codeSearch.indexOf(')');

    if (codeStart >= 0 && codeEnd >= 0) {
      setCode(codeSearch.slice(codeStart + 1, codeEnd));
    } else if (code) {
      setCode('');
    }
  }, [codeSearch]);

  return (
    <div style={{textAlign: 'left'}}>
      {rowLabel && <Body2Light marginBottom={12}>{rowLabel}</Body2Light>}

      <Row margin={-8} marginBottom={marginBottom}>
        <Col width={'45%'}>
          <AutoComplete
            label={'Country code'}
            items={PHONE_CODES_LIST}
            value={codeSearch}
            onSelect={setCodeSearch}
            onChange={setCodeSearch}
            error={codeError}
            wide
            required={required}
            disabled={disabled}
          />
        </Col>
        <Col width={'55%'}>
          <Input
            label={label}
            wide
            required={required}
            disabled={disabled}
            {...props}
          />
        </Col>
      </Row>
    </div>
  );
};
