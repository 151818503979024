import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store';
import { history } from './routing';
import { GlobalStyles } from './globalStyles';
import {QueryClientProvider} from './app/QueryClientProvider';
import { ConfigProvider } from 'antd';
import { MessageProvider } from './app/MessageProvider';


ReactDOM.render(
  <QueryClientProvider>
    <Provider store={store}>
      <Router history={history}>
        <ConfigProvider theme={{ token: { fontFamily: 'Overpass, sans-serif' } }}>
          <MessageProvider>
            <App />
            <GlobalStyles />
          </MessageProvider>
        </ConfigProvider>
      </Router>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root')
);
