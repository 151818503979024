import React from 'react';
import { Modal, Typography } from 'antd';
import { useQuery } from 'react-query';
import { investorApi, investorKeys } from '../../../../api/investor';

let generated = false;
async function generateCode(refetch) {
  try {
    generated = true;
    await investorApi.generateMyRefferalCode();
    if (refetch) refetch();
  } catch (e) {
  }
}



export const AddReferralModal = ({ open, onClose }) => {
  const { data: code, refetch } = useQuery({
    queryKey: investorKeys.getRefferalCode,
    queryFn: investorApi.getMyRefferalCode,
    select: data1 => data1.data?.referralCode,
    onSuccess: data => {
      if (!data && !generated) generateCode(refetch);
    }
  });

  return (
    <Modal open={open} onCancel={onClose} title={'Add refferal'} footer={null}>
      Share this code with your referral <Typography.Text copyable code>{code}</Typography.Text>
    </Modal>
  );
};
