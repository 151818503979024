import React, {useEffect, useState} from "react";
import {RegTitle} from "../../../ui/typography";
import {RegContainer} from "../../../components";
import {BackButton, Button, LinkButton} from "../../../ui/atoms";
import {IconView} from "../../../ui/icons";
import {useForm} from "react-hook-form";
import {FormInput} from "../../../modules/kycModule/components/FormInput";
import {authApi} from "../../../api/auth";
import {useDispatch, useSelector} from "react-redux";
import {entitiesActions} from "../../../store/entities";
import {PASSWORD_REGEX} from "../../../utils/static/app";
import {Container} from "../../../ui/layout";
import {history} from "../../../routing";


export const ResetPassword = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const {getValues, control, handleSubmit} = useForm();
  const [codeSentEmail, setCodeSentEmail] = useState((user && user.email) || '');
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  useEffect(() => {
    if (user && user.email) {
      sendCode({email: user.email});
    }
  }, []);

  const sendCode = async (data) => {
    try {
      await authApi.resetPasswordSendCode(data.email);
      setCodeSentEmail(data.email);
      dispatch(entitiesActions.addToast(user ? 'Code sent to your email' : 'Code sent'));
    } catch (e) {
      if (e?.response?.data?.message === "INVESTOR_NOT_FOUND") {
        dispatch(entitiesActions.addToast('Account not found'));
      } else {
        dispatch(entitiesActions.addToast('Something went wrong'));
      }
    }
  };

  const resetPassword = async (data) => {
    try {
      await authApi.resetPassword({email: codeSentEmail, code: data.code, newPassword: data.password});
      dispatch(entitiesActions.addToast(`Password successfully ${user ? 'changed' : 'reset'}`));
      history.push(user ? '/profile' : '/login');
    } catch (e) {
      if (e?.response?.data?.message === "CONFIRM_CODE_IS_WRONG") {
        dispatch(entitiesActions.addToast('Wrong code'));
      } else {
        dispatch(entitiesActions.addToast('Something went wrong'));
      }
    }
  };


  return (
    <RegContainer type='centered'>
      <Container marginBottom={48} style={{textAlign: 'left'}}>
        <BackButton onClick={() => history.goBack()}/>
      </Container>

      <RegTitle>{user ? 'Change': 'Reset'} password</RegTitle>

      {!codeSentEmail && <div>
        <FormInput
          label={'E-mail'}
          form={{name: 'email', control, rules: {required: 'Required'}}}
          textTransform={'lowercase'}
          marginBottom={24}
        />

        <Button
          label={'Send code'}
          onClick={() => handleSubmit(sendCode)()}
          mode={'strong'}
        />
      </div>}

      {codeSentEmail && <div>
        {!user && <Container style={{textAlign: 'left'}} marginBottom={16}>
          <LinkButton onClick={() => setCodeSentEmail('')}>
            Change account
          </LinkButton>
        </Container>}

        <FormInput
          label={'Code'}
          form={{name: 'code', control, rules: {required: 'Required'}}}
          marginBottom={16}
        />
        <FormInput
          label={'New password'}
          form={{
            name: 'password',
            control,
            rules: {
              required: 'Required',
              validate: (v) => PASSWORD_REGEX.test(v) || 'At least 10 characters, both numbers and latin symbols in upper- and lowercase.',
              deps: ['repeatPassword']
            }
          }}
          type={showPassword ? 'text' : 'password'}
          icon={<IconView onClick={() => setShowPassword(!showPassword)} />}
          marginBottom={16}
        />
        <FormInput
          label={'Repeat password'}
          form={{
            name: 'repeatPassword',
            control,
            rules: { required: 'Required', validate: (v) => getValues('password') === v || 'Passwords must match' }
          }}
          type={showPassword2 ? 'text' : 'password'}
          icon={<IconView onClick={() => setShowPassword2(!showPassword2)} />}
          marginBottom={24}
        />

        <Button
          label={`${user ? 'Change' : 'Reset'} password`}
          onClick={() => handleSubmit(resetPassword)()}
          mode={'strong'}
        />
      </div>}
    </RegContainer>
  )
};
