import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../../routing';
import { Button, Header as HeaderAragon } from '@aragon/ui';
import { ProfileViewer } from '../../../../components';
import {Row, Col, Link, Body2} from '../../../../ui';
import styled from 'styled-components';
import { userActions } from '../../../../store/user';
import {COLORS} from "../../../../utils/static/colors";

export const Header = () => {
  const dispatch = useDispatch();
  const { user, investor } = useSelector((state) => state);

  const onLogout = () => {
    dispatch(userActions.logout());
  };

  const { firstName, lastName, avatar, accountStatus } = investor;
  return (
    <Container>
      <StyledHeader
        wide={user?.userRole !== 'investor'}
        primary={
          user.userRole === 'investor' && (
            <Row margin={-6} alignCenter nowrap>
              <Col>
                <ProfileViewer
                  avatarUrl={avatar}
                  name={firstName || lastName ? `${firstName} ${lastName}` : 'Unnamed'}
                  size={'medium'}
                />
              </Col>
              {accountStatus === 'NOT_ACTIVE' && <Col>
                <Body2 color={COLORS.red} fontWeight={500}>Blocked</Body2>
              </Col>}
            </Row>
          )
        }
        secondary={
          <Row margin={-10} alignCenter>
            {user && user.userRole === 'investor' && <Link to={'/change-password'}>Change password</Link>}
            <Col auto>
              {user.userRole ? (
                <Button label={'Logout'} onClick={onLogout} />
              ) : (
                <Button label={'Login'} onClick={() => history.push('/login')} />
              )}
            </Col>
          </Row>
        }
      />
    </Container>
  );
};

const StyledHeader = styled(HeaderAragon)`
  padding: 12px 0;
  ${p => !p.wide ? `
    max-width: 1200px;
    margin: 0 auto;
  ` : ''}
`;
const Container = styled.div`
  height: 64px;
  width: 100%;
  position: sticky;
  top: 0;
  margin-bottom: 1px;
  padding: 0 24px 0 16px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  z-index: 100;
`;
