import React from 'react';
import { useLocation } from 'react-router-dom';
import { GU, TextCopy } from '@aragon/ui';
import { DeepCheckModule, EditProfileInfo } from '../../../../../modules';
import { ProfileViewer, Box, InnerBox, BoxLine } from '../../../../../components';
import { Body2, Body2Light, Button, Col, Row, Title4 } from '../../../../../ui';
import { history } from '../../../../../routing';
import { ENABLED_STATUSES_INVESTOR } from '../../../../../utils/static/kyc';
import { COLORS } from '../../../../../utils/static/colors';
import { dateString } from '../../../../../utils/helpers/common';
import { DISPLAY_INVESTOR_ACCOUNT_STATUS } from '../../../../../utils/static/profile';
import { ProgressBar } from '@aragon/ui';
import styled from 'styled-components';
import { Steps, Typography } from 'antd';

const steps = ['Tier 1', 'Tier 2', 'Tier 3'];

export const ProfileInfoView = (
  {
    investor,
    isEdit,
    saveTab,
    saveCallback,
    viewMode = 'investor'
  }) => {
  const location = useLocation();

  if (investor) {
    const {
      id,
      avatar,
      firstName,
      lastName,
      citizenship,
      phone,
      email,
      tier,
      accountStatus,
      createdAt,
      lastSession,
      scoringPoints,
      referralCode
    } = investor;

    if (!isEdit) {
      return (
        <div>
          <Box padding={0} innerPadding={2 * GU} borderRadius={12}>
            {viewMode === 'investor' && (
              <InnerBox>
                <Title4 marginBottom={16}>Tier progress</Title4>

                {/*<Row justifyContent={'space-between'}>*/}
                {/*  <Body2>Tier 1</Body2>*/}
                {/*  <Body2>Tier 2</Body2>*/}
                {/*  <Body2>Tier 3</Body2>*/}
                {/*</Row>*/}
                {/*<ProgressBarContainer>*/}
                {/*  <ProgressBar*/}
                {/*    color={COLORS.mintGreen}*/}
                {/*    value={(tier - 1) / 2}*/}
                {/*  />*/}
                {/*  <Bullet1 active={tier >= 1} />*/}
                {/*  <Bullet2 active={tier >= 2} />*/}
                {/*  <Bullet3 active={tier >= 3} />*/}
                {/*</ProgressBarContainer>*/}

                <ProgressContainer>
                  <Steps current={tier - 1} items={steps.map(item => ({ key: item, title: item }))} />
                </ProgressContainer>
              </InnerBox>
            )}

            <InnerBox>
              <Row margin={-16}>
                <Col width={viewMode === 'admin' ? '56%' : '100%'}>
                  {viewMode === 'investor' && <Title4 marginBottom={16}>Personal data</Title4>}
                  <ProfileViewer
                    avatarUrl={avatar && avatar}
                    name={`${firstName} ${lastName}`}
                    type='short'
                    marginBottom={16}
                  />

                  <BoxLine title='Phone number' data={phone} />
                  <BoxLine title='E-mail' data={email} />
                  <BoxLine title='Citizenship' data={citizenship?.country} />
                </Col>
                {viewMode === 'admin' && (
                  <Col width={'44%'} style={{ borderLeft: `1px solid ${COLORS.greyBasic}` }}>
                    <InnerBox>
                      Status: <strong>{DISPLAY_INVESTOR_ACCOUNT_STATUS[accountStatus]}</strong>
                    </InnerBox>
                    <InnerBox>
                      <div>
                        Personal ID: <strong>{id}</strong>
                      </div>
                      <div>
                        Registered:{' '}
                        <strong>{createdAt ? dateString(new Date(createdAt)) : '-'}</strong>
                      </div>
                      <div>
                        Last session:{' '}
                        <strong>{lastSession ? dateString(new Date(lastSession)) : '-'}</strong>
                      </div>
                      <div>
                        Tier level: <strong>Tier {tier}</strong>
                      </div>
                      <div>
                        Scoring points: <strong>{scoringPoints}</strong>
                      </div>
                    </InnerBox>
                    <InnerBox>Legal entity connections: none</InnerBox>
                  </Col>
                )}
              </Row>
            </InnerBox>

            {viewMode === 'investor' && referralCode && <InnerBox>
              <BoxLine title='Referral code'>
                <Typography.Text copyable style={{fontSize: '16px'}}>{referralCode}</Typography.Text>
              </BoxLine>
            </InnerBox>}
          </Box>

          {(viewMode === 'admin' || viewMode === 'superAdmin') && (
            <DeepCheckModule investorId={id} viewMode={viewMode} />
          )}

          {investor.additionalInfo && (
            <Box heading={''} borderRadius={12}>
              <Row marginBottom={16} margin={-24}>
                <Title4>Additional information</Title4>
                {viewMode === 'investor' &&
                ENABLED_STATUSES_INVESTOR[investor?.additionalInfo?.status] && (
                  <Button
                    label={`Fill ${
                      investor?.account ? `${investor.account.toLowerCase()} data` : ''
                    } questionnaire`}
                    onClick={() => {
                      history.push(
                        `/questionnaire/${investor?.additionalInfo?.id}`,
                        {
                          prevPath: location.pathname, nextState: { lastProfileTab: investor.lastProfileTab }
                        });
                    }}
                    size={'small'}
                    mode={'strong'}
                    disabled={accountStatus !== 'ACTIVE'}
                  />
                )}
              </Row>

              {investor.additionalInfo.answers.map((item, key) => (
                <BoxLine title={item.title} data={item.answer || '-'} type={item.type} key={key} />
              ))}
            </Box>
          )}
        </div>
      );
    } else {
      return <EditProfileInfo investor={investor} saveTab={saveTab} saveCallback={saveCallback} />;
    }
  }

  return null;
};


const ProgressBarContainer = styled.div`
  position: relative;
  margin-top: 16px;
  margin-bottom: 20px;
`;
const ProgressContainer = styled.div`
  margin-bottom: 8px;
  font-weight: 400;
`;
const Bullet = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: ${p => p.active ? COLORS.mintGreen : COLORS.darkBabyBlue};
    width: 16px;
    height: 16px;
    border-radius: 50%;
    `;
const Bullet1 = styled(Bullet)`
    `;
const Bullet2 = styled(Bullet)`
    left: 50%;
    transform: translate(-50%, -50%);
    `;
const Bullet3 = styled(Bullet)`
    left: 100%;
    transform: translate(-100%, -50%);
    `;
