import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { kycApi } from '../../../../api/kyc';
import { DocRequestView } from '../../../../components';
import { useDispatch } from 'react-redux';
import { entitiesActions } from '../../../../store/entities';

export const DocumentRequest = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { data, refetch, isFetching } = useQuery(['getDocument'], () =>
    kycApi.getDocumentRequest(id)
  );

  useEffect(() => {
    isFetching
      ? dispatch(entitiesActions.showLoader('Loading...'))
      : dispatch(entitiesActions.hideLoader());
  }, [isFetching]);

  return (
    <DocRequestView
      isFetching={isFetching}
      data={data}
      refetch={refetch}
      requestId={id}
    />
  );
};
