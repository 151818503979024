import axios from 'axios';
import { API_URL } from '../../utils/static';
import { getAssets, getAsset, getMyAssets, makeOrder } from './assets';
import { getOrders, cancelOrder } from './orders';
import { getDeals } from './deals';
import { getTransactions, makeTransfer, transferSendCode, submitTransfer } from './transactions';
import {
  getMyRefferalCode,
  getMyRecommenderAccounts,
  getMyRefferalAccounts,
  setRefferalCode,
  generateMyRefferalCode
} from './referrals';

export const createInvestor = (data) => {
  return axios.post(`${API_URL}/api/v1/investor`, data);
};

export const updateInvestor = (data) => {
  return axios.put(`${API_URL}/api/v1/investor`, data);
};

export const setCitizenship = (countryId) => {
  return axios.post(`${API_URL}/api/kyc/v1/investor/citizenship`, { countryId });
};

export const uploadAvatar = (file) => {
  const formData = new FormData();
  formData.append('file', file);

  return axios.post(`${API_URL}/api/kyc/v1/investor/avatar`, formData);
};

export const verifyPhone = (code) => {
  return axios.post(`${API_URL}/api/v1/investor/verifyPhone`, { code: code });
};

export const verifyEmail = (code) => {
  return axios.post(`${API_URL}/api/v1/investor/verifyEmail`, { code: code });
};

export const sendPhoneCode = () => {
  return axios.get(`${API_URL}/api/v1/investor/sendPhoneSms`);
};

export const sendEmailCode = () => {
  return axios.get(`${API_URL}/api/v1/investor/sendEmailSms`);
};

export const addEthereumAddress = (address) => {
  return axios.post(`${API_URL}/api/v1/investor/ethereumAddress`, { ethereumAddress: address });
};

export const addGeneralInfo = (data) => {
  return axios.put(`${API_URL}/api/v1/investor`, data);
};

export const addAddress = (data) => {
  return axios.post(`${API_URL}/api/v1/investor/address`, data);
};

export const addIdDocument = (data) => {
  let reqBody = new FormData();
  Object.keys(data).forEach((key) => {
    reqBody.append(key, data[key]);
  });

  return axios.post(`${API_URL}/api/v1/investor/idDocument`, reqBody);
};

export const addPaymentDetails = (data) => {
  let reqBody = new FormData();
  Object.keys(data).forEach((key) => {
    reqBody.append(key, data[key]);
  });

  return axios.post(`${API_URL}/api/v1/investor/paymentDetails`, reqBody);
};

export const phoneEmailValidation = (phone, email) => {
  return axios.get(`${API_URL}/api/v1/investor/validation`, {
    params: { phone: phone, email: email }
  });
};

export const addProofOfFunds = (file) => {
  let reqBody = new FormData();
  reqBody.append('proofOfFunds', file);

  return axios.post(`${API_URL}/api/v1/investor/proofOfFunds`, reqBody);
};

export const addMIFID = (file) => {
  let reqBody = new FormData();
  reqBody.append('mifid', file);

  return axios.post(`${API_URL}/api/v1/investor/mifid`, reqBody);
};

export const addKYC = (file) => {
  let reqBody = new FormData();
  reqBody.append('kyc', file);

  return axios.post(`${API_URL}/api/v1/investor/kyc`, reqBody);
};

export const createPersonalAccount = (accountName = 'Personal account') => {
  return axios.post(`${API_URL}/api/v1/investor/personalAccount`, { accountName: accountName });
};

export const getInvestor = () => {
  return axios.get(`${API_URL}/api/v1/investor`);
};
const getCitizenship = () => {
  return axios.get(`${API_URL}/api/kyc/v1/investor/citizenship`);
};
const getAdditionalInfoQuestId = () => {
  return axios.get(`${API_URL}/api/kyc/v1/investor/questionnaires/PersonalData`);
};

export const getIDDocument = () => {
  return axios.get(`${API_URL}/api/v1/investor/idDocument`);
};

export const getAddresses = () => {
  return axios.get(`${API_URL}/api/v1/investor/address`);
};

export const getPaymentsDetails = () => {
  return axios.get(`${API_URL}/api/v1/investor/paymentDetails`);
};

export const getDocument = () => {
  return axios.get(`${API_URL}/api/v1/investor/investorDocuments`);
};

export const deleteDocument = (id) => {
  return axios.delete(`${API_URL}/api/v1/investor/investorDocuments`, {
    data: { investorDocumentId: id }
  });
};

export const getMyCash = () => {
  return axios.get(`${API_URL}/api/v1/investor/myCash`);
};

export const investorApi = {
  createInvestor,
  updateInvestor,
  setCitizenship,
  uploadAvatar,
  verifyPhone,
  verifyEmail,
  sendPhoneCode,
  sendEmailCode,
  addEthereumAddress,
  addGeneralInfo,
  addAddress,
  addIdDocument,
  addPaymentDetails,
  phoneEmailValidation,
  addProofOfFunds,
  addMIFID,
  addKYC,
  createPersonalAccount,
  getInvestor,
  getCitizenship,
  getAdditionalInfoQuestId,
  getIDDocument,
  getAddresses,
  getPaymentsDetails,
  getDocument,
  getAssets,
  getAsset,
  getMyAssets,
  makeOrder,
  getOrders,
  cancelOrder,
  getDeals,
  getTransactions,
  makeTransfer,
  transferSendCode,
  submitTransfer,
  deleteDocument,
  getMyCash,
  getMyRefferalCode, getMyRecommenderAccounts, getMyRefferalAccounts, setRefferalCode, generateMyRefferalCode
};

export const investorKeys = {
  getRefferalCode: 'investor.getMyRefferalCode',
  getRefferalAccounts: 'investor.getMyRefferalAccounts',
  getRecommendersAccounts: 'investor.getMyRecommenderAccounts'
};
