import { userReducer } from '../../../store/user';
import { investorReducer } from '../../../store/investor';
import { authApi } from '../../../api';
import { history } from '../../../routing';
import axios from 'axios';
import { entitiesActions } from '../../entities';

export const auth = (data, callback) => async (dispatch) => {
  dispatch(entitiesActions.showLoader('Loading'));

  await authApi
    .auth(data)
    .then((res) => {
      if (res.status === 200) {
        const { data } = res;
        dispatch(investorReducer.setInvestor(data));
        dispatch(userReducer.setEmail(data.email));

        callback();
      }
    })
    .catch((e) => {
      const errorMessage = e.response.data.message;
      if (errorMessage === 'WRONG_PASSWORD') {
        dispatch(entitiesActions.addToast('Wrong password'));
      }
    })
    .finally(() => {
      dispatch(entitiesActions.hideLoader());
    });
};

export const setTokenByEmail = (data) => async (dispatch) => {
  dispatch(entitiesActions.showLoader('Loading'));

  await authApi
    .getToken(data)
    .then((res) => {
      if (res.status === 200) {
        const { data } = res;
        dispatch(setToken(data));
      }
    })
    .catch((e) => {
      const errorMessage = e.response.data.message;
      if (errorMessage === 'CONFIRM_CODE_IS_WRONG') {
        dispatch(entitiesActions.addToast('Wrong code'));
      }
    })
    .finally(() => {
      dispatch(entitiesActions.hideLoader());
    });
};

export const setToken = (data) => (dispatch) => {
  const { accessToken, userRole } = data;

  localStorage.setItem('token', accessToken);
  localStorage.setItem('userRole', userRole);
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  dispatch(setRole(userRole));
};

export const setRole = (userRole) => (dispatch) => {
  dispatch(userReducer.setRole(userRole));
};

export const hideAppContainer = () => (dispatch) => {
  dispatch(userReducer.setHideAppContainer(true));
};

export const showAppContainer = () => (dispatch) => {
  dispatch(userReducer.setHideAppContainer(false));
};

export const logout = () => (dispatch) => {
  localStorage.removeItem('userRole');
  localStorage.removeItem('token');
  dispatch(userReducer.logout());
};
