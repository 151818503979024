import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {useMutation, useQuery} from 'react-query';
import { adminKYCApi } from '../../../../api/admin/kyc';
import { QuestionnaireView } from '../../../../modules/Questionnaire/View';
import { SyncIndicator } from '@aragon/ui';
import { RejectModal } from '../../../../modules/Questionnaire/components/modals/RejectModal';
import { Toast } from '../../../../modules/kycModule/components/Toast';
import { history, scrollToTop } from '../../../../routing';
import {SHOWED_SECTIONS_COUNT} from '../../../../utils/static/kyc';
import {useDispatch} from "react-redux";
import {entitiesActions} from "../../../../store/entities";

export const SumsubAnswers = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [currentSection, setCurrentSection] = useState(0);
  const { data: res, isLoading } = useQuery(['getInvestorSumSubAnswers', id], () =>
    adminKYCApi.getInvestorSumSubAnswers(id)
  );
  const { data: sumSubStatusRes, refetch: refetchStatus } = useQuery(['getInvestorSumSubStatus', id], () =>
    adminKYCApi.getInvestorSumSubStatus(id)
  );
  const {mutateAsync: setSumSubStatus} = useMutation(adminKYCApi.setInvestorSumSubStatus);

  const stepsCount = useMemo(() => {
    if (res?.data?.questionSections)
      return Math.ceil(res.data.questionSections.length / SHOWED_SECTIONS_COUNT);
    return 0;
  }, [res]);
  const statusSettings = useMemo(() => {
    const status = sumSubStatusRes?.data?.investorSumSubQuestionSection?.status;

    if (status) {
      const isRejected = status === 'Rejected';
      const isApproved = status === 'Accepted';
      return {
        isRejected: isRejected,
        isApproved: isApproved,
        allowReject: !isRejected,
        allowApprove: !isApproved,
      }
    }

    return null;
  }, [sumSubStatusRes]);

  const onNext = () => {
    if (currentSection + 1 < stepsCount) {
      setCurrentSection(currentSection + 1);
      scrollToTop();
    }
  };
  const onBack = () => {
    if (currentSection > 0) {
      setCurrentSection(currentSection - 1);
      scrollToTop();
    }
  };

  const setStatus = async (status, successText) => {
    try {
      await setSumSubStatus({investorId: id, status: status});
      dispatch(entitiesActions.addToast(successText));
      await refetchStatus();
    } catch (e) {
      let errorMessage = e?.response?.data?.error?.message;
      if (!errorMessage) errorMessage = 'Something went wrong';

      dispatch(entitiesActions.addToast(errorMessage));
      console.log(e);
    }
  };

  return (
    <div>
      {!isLoading && res && res.data && (
        <QuestionnaireView
          questionnaire={{ questionSections: res.data }}
          currentSection={currentSection}
          onNext={onNext}
          onBack={onBack}
          acceptQuestionnaire={() => setStatus('GREEN', 'Accepted')}
          rejectQuestionnaire={() => setStatus('RED', 'Rejected')}
          statusSettings={statusSettings}
          adminView
        />
      )}

      {!res?.data && <div>Something went wrong</div>}

      {/*<RejectModal*/}
      {/*  active={isModalActive}*/}
      {/*  onClose={() => setModalActive(false)}*/}
      {/*  comment={comment}*/}
      {/*  setComment={setComment}*/}
      {/*  error={commentError}*/}
      {/*  onSubmit={rejectQuestionnaire}*/}
      {/*/>*/}

      <SyncIndicator visible={isLoading || !res}>Loading...</SyncIndicator>
      {/*<Toast*/}
      {/*  toast={toast}*/}
      {/*  removeToast={() => setToast('')}*/}
      {/*/>*/}
    </div>
  );
};
