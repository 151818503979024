import React, { useContext, useState } from 'react';
import { Modal, Typography } from 'antd';
import { Input } from 'antd';
import { investorApi, investorKeys } from '../../../../api/investor';
import { MessageContext } from '../../../../app/MessageProvider';
import { queryClient } from '../../../../app/QueryClientProvider';
import { getErrorMessage } from '../../../../utils/helpers/common';
import { Container } from '../../../../ui/layout';

export const AddRecommenderModal = ({ open, onClose }) => {
  const [code, setCode] = useState('');
  const messageApi = useContext(MessageContext);

  const setRefferalCode = async () => {
    if (code) {
      try {
        await investorApi.setRefferalCode(code);

        messageApi.success('Refferal code successfully added');
        await queryClient.refetchQueries({ queryKey: [investorKeys.getRecommendersAccounts] });
        onClose();
        setCode('');
      } catch (e) {
        console.error(e);
        messageApi.error(getErrorMessage(e));
      }
    } else {
      messageApi.warning('Please enter referral code');
    }
  };

  return (
    <Modal open={open} onCancel={onClose} title={'Add recommender'} onOk={setRefferalCode}>
      <Container marginBottom={4}>
        <Typography.Text>Code</Typography.Text>
      </Container>
      <Input
        value={code}
        onChange={(e) => setCode(e.target.value)}
        placeholder={'Enter code'}
      />
    </Modal>
  );
};
