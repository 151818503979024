import React from 'react';
import { RegLoginRoutes } from './RegLoginRouter';
import { AppContainer } from '../components/common/organisms/AppContainer';
import { InvestorRoutes } from './InvestorRouter';
import { AdminRoutes } from './AdminRouter';
import { SuperAdminRoutes } from './SuperAdminRouter';

export const RootRouter = ({ routerMode, hideAppContainer }) => {
  if (routerMode === 'reg') {
    return <RegLoginRoutes />;
  }

  return (
    <AppContainer hideAppContainer={hideAppContainer}>
      {routerMode === 'investor' && <InvestorRoutes />}
      {routerMode === 'admin' && <AdminRoutes />}
      {routerMode === 'superAdmin' && <AdminRoutes />}
    </AppContainer>
  );
};
