import React from 'react';
import { RegContainer, StepsPanel, FooterButtons } from '../../../../../components';
import styled from 'styled-components';
import { Link, Title2, Label2, Body2Light } from '../../../../../ui';
import { TextInput, GU } from '@aragon/ui';
import { individualSteps } from '../../../../../utils/static';
import { history } from '../../../../../routing';

export const Step10 = () => {
  return (
    <StepsPanelContainer>
      <RegContainer type='centered'>
        <Title>Authorize your account</Title>

        <Subtitle>
          [The verification code has been sent on your e-mail]
          {/*<Link>username@gmail.com</Link>*/}
        </Subtitle>

        <InputLabel>Verification code</InputLabel>
        <TextInput />

        <RepeatSendTitle>
          Didn’t recieve the code? <Link>Try again</Link>
        </RepeatSendTitle>

        <FooterButtons onNext={() => {}} />
      </RegContainer>
    </StepsPanelContainer>
  );
};

const StepsPanelContainer = styled.div`
  display: flex;
`;
const Title = styled(Title2)`
  font-weight: 300;
  margin-bottom: ${3 * GU}px;
`;
const Subtitle = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: #212b36;
  margin-bottom: 62px;
`;
const InputLabel = styled(Label2)``;
const RepeatSendTitle = styled.p`
  margin: ${GU * 5}px 0 ${GU * 8}px 0;
`;
