import { investorActions, investorReducer } from '../../../store/investor';
import { adminApi, adminKYCApi, investorApi, kycApi } from '../../../api';
import {entitiesActions, entitiesReducer} from '../../entities';
import { calcAdditionalInfo, formatInvestorObject } from '../../../utils/helpers/investor';

export const setInvestor = (investor) => async (dispatch) => {
  dispatch(investorReducer.setInvestor(investor));
};

export const setInvestorIdDoc = (idDoc) => (dispatch) => {
  dispatch(investorReducer.setInvestor({ idDocument: idDoc }));
};

export const setInvestorPaymentDetails = (paymentDetails) => (dispatch) => {
  dispatch(investorReducer.setInvestor({ paymentDetails: paymentDetails }));
};

export const getInvestor =
  (mainOnly = false) =>
  async (dispatch) => {
    try {
      const resInvestor = await investorApi.getInvestor();

      if (resInvestor.status === 200) {
        const investorObject = formatInvestorObject(resInvestor.data);
        dispatch(investorReducer.setInvestor(investorObject));
      }

      const resCitizenship = await investorApi.getCitizenship();
      if (resCitizenship.status === 200) {
        dispatch(investorReducer.setCitizenship(resCitizenship.data[0]));
      }

      // dispatch(getAdditionalInfo());

      return resInvestor.data;
    } catch (e) {
      console.error(e);
    }
  };
export const getAdditionalInfo = () => async (dispatch) => {
  try {
    const resQuestId = await investorApi.getAdditionalInfoQuestId();
    const resQuest = await kycApi.getQuestionnaireSections(resQuestId?.data?.id);
    const additionalInfo = calcAdditionalInfo(resQuest.data);
    if (additionalInfo) dispatch(investorReducer.setAdditionalInfo(additionalInfo));
  } catch (e) {
    console.error(e);
  }
};

export const setVerifiedEmail = (verified) => async (dispatch) => {
  dispatch(investorReducer.setVerifiedEmail(verified));
};

export const setVerifiedPhone = (verified) => async (dispatch) => {
  dispatch(investorReducer.setVerifiedPhone(verified));
};

export const setEthereumAddress = (address) => async (dispatch) => {
  dispatch(investorReducer.setEthereumAddress(address));
};

export const setAddresses = (addresses) => async (dispatch) => {
  dispatch(investorReducer.setAddresses(addresses));
};

export const fetchPayments = () => async (dispatch) => {
  investorApi.getPaymentsDetails().then((res) => {
    if (res.status === 200) {
      dispatch(investorReducer.setInvestor({ paymentDetails: res.data }));
    }
  });
};

export const resetInvestor = () => async (dispatch) => {
  dispatch(investorReducer.resetInvestor());
};

export const fetchIdDocument = () => async (dispatch) => {
  investorApi.getIDDocument().then((res) => {
    if (res.status === 200) {
      dispatch(investorActions.setInvestor({ idDocument: res.data[0] }));
    }
  });
};

export const getDocuments = () => async (dispatch) => {
  investorApi.getDocument().then((res) => {
    console.log(res.data);
    if (res.status === 200) {
      dispatch(investorReducer.setDocuments(res.data));
    }
  });
};

export const deleteDocument = (id) => async (dispatch) => {
  investorApi.deleteDocument(id).then((res) => {
    if (res.status === 200) {
      dispatch(getDocuments());
    }
  });
};

export const getCashSummary = () => async (dispatch) => {
  await investorApi.getMyCash().then((res) => {
    if (res.status === 200) {
      dispatch(investorReducer.setCashSummary(res.data));
    }
  });
};

export const fetchInvestorProfile = (id, dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resInvestor = await adminApi.getInvestor(id);
      const investorObject = formatInvestorObject(resInvestor.data);
      dispatch(investorReducer.setInvestor(investorObject));

      const resCitizenship = await adminApi.getInvestorCitizenship(id);
      dispatch(investorReducer.setCitizenship(resCitizenship.data[0]));

      await getInvestorAdditionalInfo(id, dispatch);

      // const {data} = await adminKYCApi.getDeepCheckStatuses();
      // if (data) {
      //   dispatch(investorReducer.set)
      // }

      resolve();
    } catch (e) {
      console.error(e);
      reject();
    }
  });
};
const getInvestorAdditionalInfo = (id, dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resQuestId = await adminApi.getInvestorAddInfoQuestId(id);
      const resQuest = await adminKYCApi.getQuestionnaireAnswers({
        investorId: id,
        questionnaireId: resQuestId?.data?.id
      });
      const additionalInfo = calcAdditionalInfo(resQuest.data);
      dispatch(investorReducer.setAdditionalInfo(additionalInfo));

      resolve();
    } catch (e) {
      console.error(e);
      reject();
    }
  });
};

export const updateInvestor = (
  { id, investorInfo, legalAddress, actualAddress, idDocs },
  dispatch
) => {
  dispatch(entitiesActions.showLoader('Saving...'));

  const onResolve = async (resolve) => {
    await dispatch(fetchInvestorProfile(id));
    dispatch(entitiesActions.hideLoader());
    resolve();
  };
  const onReject = (reject) => {
    reject();
  };

  return new Promise((resolve, reject) => {
    Promise.all([
      adminApi.updateInvestor({ investorId: id, ...investorInfo }),
      adminApi.updateInvestorAddress({ investorId: id, ...legalAddress }),
      adminApi.updateInvestorAddress({ investorId: id, ...actualAddress }),
      adminApi.updateInvestorIdDoc({ investorId: id, ...idDocs })
    ]).then(
      () => onResolve(resolve),
      () => onReject(reject)
    );
  });
};

export const setLastProfileTab = (tab) => (dispatch) => {
  dispatch(investorReducer.setLastProfileTab(tab));
};
