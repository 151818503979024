import React from 'react';
import { OptionCard, RegContainer, Info } from '../../../components';
import { BackButton, GU } from '@aragon/ui';
import {Link, Row, Title2, Label, ExternalLink} from '../../../ui';
import { useTheme } from '@aragon/ui';
import useStyles from './styles';
import textStyle from '../../../utils/textStyle';
import { COLORS } from '../../../utils/static/colors';
import CreateCompanyIcon from '../../../assets/entry-pages/create-company.svg';
import CreatePersonalIcon from '../../../assets/entry-pages/create-personal.svg';

const InvestorRegStart = ({ history }) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <RegContainer type='centered'>
      <Title2 marginBottom={7 * GU}>Create your own account in a few minutes!</Title2>

      <Row margin={-GU}>
        <OptionCard
          title={'Create personal \naccount'}
          className={'col-auto'}
          onClick={() => {
            history.push('/registration/investor/1', { account: 'PERSONAL' });
          }}
          icon={CreatePersonalIcon}
        />

        <OptionCard
          title={'Create \ncompany account'}
          className={'col-auto'}
          onClick={() => {
            history.push('/registration/investor/1', { account: 'COMPANY' });
          }}
          icon={CreateCompanyIcon}
        />
      </Row>

      <Info title={<Label color={COLORS.aragonDarkBlue}>Disclaimer</Label>}>
        The Service is generally available only to persons of legal age and legal capacity under local law.
        If you have any questions, please contact us - <ExternalLink href={'mailto:compliance@casecapital.co.il'} external>
          compliance@casecapital.co.il
        </ExternalLink>
        <br />
      </Info>

      <BackButton
        className={styles.backButton}
        onClick={() => {
          history.push('/');
        }}
      />

      <div className={styles['login-title']} style={textStyle('body1')}>
        Already have an account? <Link to={'/login'}>Log in</Link>.
      </div>
    </RegContainer>
  );
};

export default InvestorRegStart;
