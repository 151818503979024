import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { investorApi } from '../../../../api/investor';
import { newObject } from '../../../../utils/helpers';
import { investorActions } from '../../../../store/investor';
import { useDispatch } from 'react-redux';
import {RegContainer, StepsPanel, FooterButtons, ImageUploadBoxForm} from '../../../../components';
import styled from 'styled-components';
import {
  Link,
  Title2,
  Label2,
  IconCross,
  IconCheck,
  IconView,
  Input,
  AutoComplete,
  ExternalLink
} from '../../../../ui';
import {CountryInput, PhoneInput} from "../../../../modules/kycModule/Fields";
import { Container } from '../../../../ui/layout';
import { GU, Checkbox } from '@aragon/ui';
import {
  EMAIL_REGEX,
  PASSWORD_REGEX,
  individualSteps,
  API_URL,
  PHONE_CODES
} from '../../../../utils/static';
import { entitiesActions } from '../../../../store/entities';
import { history } from '../../../../routing';
import { useCountries } from '../../../../hooks';
import {useForm} from "react-hook-form";
import {FormInput} from "../../../../modules/kycModule/components/FormInput";
import {kycApi} from "../../../../api/kyc";


export const Step1 = ({ stepNumber, goNext }) => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const COUNTRIES = useCountries();
  const { control, handleSubmit, getValues, setError } = useForm();
  const [isEmailValid, setEmailValid] = useState(null);
  const [isPhoneValid, setPhoneValid] = useState(null);
  const [isShowPassword, setShowPassword] = useState(false);
  const [isShowPassword2, setShowPassword2] = useState(false);
  const [isTermsChecked, setTermsChecked] = useState(false);

  useEffect(() => {
    if (!state || !state.account) history.push('/registration/investor');
  }, []);


  const checkValidation = (email, phone) => {
    return new Promise((resolve, reject) => {
      if (email || phone) {
        investorApi
          .phoneEmailValidation(phone, email.toLowerCase())
          .then((res) => {
            if (res.status === 200) {
              const { data } = res;

              setEmailValid(data.validEmail);
              setPhoneValid(data.validPhone);
              if (!data.validEmail || !data.validPhone) {
                reject('UNIQUE_FAILED');
              }

              resolve();
            } else {
              reject();
            }
          })
          .catch(() => {
            reject();
          });
      }
    });
  };

  const getCitizenshipObj = (citizenship) => {
    return COUNTRIES.filter((item) => item.country === citizenship)[0];
  };

  const handleNext = async (data) => {
    try {
      dispatch(entitiesActions.showLoader('Processing...'));

      const { email, phone, password, firstName, lastName, avatar, citizenship } = data;

      await checkValidation(email, phone.replace('/', ''));

      const citizenshipId = getCitizenshipObj(citizenship);
      if (!citizenshipId || !citizenshipId.id) {
        setError('citizenship', { type: 'custom', message: 'Citizenship must be selected from the list' });
        throw new Error('Citizenship');
      }

      const res = await investorApi.createInvestor({
        email: email.toLowerCase(),
        phone: phone.replace('/', ''),
        password: password,
        account: state.account,
        firstName: firstName,
        lastName: lastName
      });

      if (res.status === 201) {
        const { accessToken, role, ...investor } = res.data;
        localStorage.setItem('token', accessToken);
        localStorage.setItem('userRole', role);
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        dispatch(investorActions.setInvestor(investor));

        goNext();

        await investorApi.setCitizenship(citizenshipId.id);
        if (avatar) await investorApi.uploadAvatar(avatar);
      }
    } catch (e) {
      console.log('addPersonalData', e);
      if (e === 'UNIQUE_FAILED') {
        dispatch(entitiesActions.addToast('Email or phone is already taken'));
      } else {
        dispatch(entitiesActions.addToast('Something went wrong'));
      }
      // checkValidation();
    } finally {
      dispatch(entitiesActions.hideLoader());
      kycApi.generateQuestionnaires().then().catch();
    }
  };


  return (
    <StepsPanelContainer>
      <StepsPanel title={'Registration'} step={stepNumber} steps={individualSteps} />

      <RegContainer type='centered'>
        <Title onClick={goNext}>Account creation</Title>

        <FormInput
          label={'E-mail'}
          form={{
            control,
            name: 'email',
            rules: { required: 'Required', validate: (v) => EMAIL_REGEX.test(v.toLowerCase()) || 'Invalid e-mail address'}
          }}
          icon={isEmailValid ? <IconCheck /> : <IconCross hide={isEmailValid === null} />}
          textTransform={'lowercase'}
          marginBottom={3 * GU}
          required
        />

        <PhoneInput
          form={{ control, name: 'phone', rules: {required: 'Required'} }}
          icon={isPhoneValid ? <IconCheck /> : <IconCross hide={isPhoneValid === null} />}
          marginBottom={3 * GU}
          required
        />

        <FormInput
          form={{ control, name: 'firstName', rules: {required: 'Required'} }}
          label={'First name'}
          maxLength={20}
          marginBottom={3 * GU}
          marginRight={6 * GU}
          required
        />

        <FormInput
          form={{ control, name: 'lastName', rules: {required: 'Required'} }}
          label={'Last name'}
          maxLength={20}
          marginBottom={3 * GU}
          marginRight={6 * GU}
          required
        />

        <Container marginBottom={3 * GU} paddingRight={48}>
          <CountryInput
            label={'Citizenship'}
            form={{ control, name: 'citizenship', rules: {required: 'Required'} }}
            required
            wide
          />
        </Container>

        <Container marginBottom={3 * GU}>
          <ImageUploadBoxForm
            label={'avatar'}
            form={{ control, name: 'avatar' }}
            hideInfo
          />
        </Container>

        <FormInput
          form={{
            control,
            name: 'password',
            rules: {
              required: 'Required',
              pattern: {
                value: PASSWORD_REGEX,
                message: 'At least 10 characters, both numbers and latin symbols in upper- and lowercase.'
              },
              deps: ['repeatPassword']
            }
          }}
          type={isShowPassword ? 'text' : 'password'}
          label={'Password'}
          icon={<IconView onClick={() => setShowPassword(!isShowPassword)} />}
          marginBottom={3 * GU}
          required
        />

        <FormInput
          form={{
            control,
            name: 'repeatPassword',
            rules: {
              required: 'Required',
              validate: (v) => getValues('password') === v || 'Passwords must match'
            }
          }}
          type={isShowPassword2 ? 'text' : 'password'}
          label={'repeat password'}
          icon={<IconView onClick={() => setShowPassword2(!isShowPassword2)} />}
          marginBottom={3 * GU}
          required
        />

        <CheckboxWrapper>
          <Checkbox checked={isTermsChecked} onChange={(checked) => setTermsChecked(checked)} />
          <span>
            I agree with{' '}
            <ExternalLink href={`${API_URL}/api/v1/statics/privacy_policy.pdf`}>
              Privacy Policy
            </ExternalLink>
          </span>
        </CheckboxWrapper>

        <FooterButtons onNext={handleSubmit(handleNext)} nextDisabled={!isTermsChecked} />
      </RegContainer>
    </StepsPanelContainer>
  );
};

const StepsPanelContainer = styled.div`
  display: flex;
`;
const Title = styled(Title2)`
  font-weight: 300;
  margin-bottom: ${8 * GU}px;
`;
const IconCheckWrapper = styled.div`
  width: ${4 * GU}px;
  height: ${4 * GU}px;
  padding: 5px;
  background: #2cc68f;
  color: #ffffff;
  margin-left: ${2 * GU}px;
  border-radius: 100%;
`;
const CheckboxWrapper = styled.label`
  display: flex;
  margin-bottom: 34px;
  align-items: center;

  & > span {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #212b36;
    margin-left: ${GU}px;
  }
`;
